import { Component } from '../helpers/helper';
import { router } from '../router';

const siteTitle = 'GatherAct';

export const FluffPage = Component(() => {
	const el = Vue.ref(null);
	let r = fluffRoutes.find(r => r.path === router.currentRoute.value.path);
	fetch(r.html).then(r =>
		r.text().then(t => {
			el.value.innerHTML = t;
		})
	);
	return () => <div class="store-page" ref={el}></div>;
});

export const fluffRoutes = [
	{
		path: '/resources/Using-YouTube-with-Zoom-Collaboratively',
		name: 'Using YouTube with Zoom (Collaboratively)',
		html: '/fluff/fluff_youtube.html',
		component: FluffPage,
		meta: {
			title: siteTitle + ' - Using YouTube with Zoom (Collaboratively)',
			metaTags: [
				{
					name: 'description',
					content: 'Learn how to watch YouTube with Zoom or in any online meeting.'
				}
			]
		}
	},
	{
		path: '/resources/Apps-For-Zoom',
		name: 'Apps For Zoom',
		html: '/fluff/fluff_apps_for_zoom.html',
		component: FluffPage,
		meta: {
			title: siteTitle + ' - Apps For Zoom',
			metaTags: [
				{
					name: 'description',
					content: 'How to use collaborative apps in a Zoom meeting'
				}
			]
		}
	},
	{
		path: '/resources/Apps-For-Discord',
		name: 'Apps For Discord',
		html: '/fluff/fluff_apps_for_discord.html',
		component: FluffPage,
		meta: {
			title: siteTitle + ' - Apps For Discord',
			metaTags: [
				{
					name: 'description',
					content: 'How to use collaborative apps in Discord'
				}
			]
		}
	},
	{
		path: '/resources/Apps-For-Google-Meet',
		name: 'Apps For Google Meet',
		html: '/fluff/fluff_apps_for_google_meet.html',
		component: FluffPage,
		meta: {
			title: siteTitle + ' - Apps For Google Meet',
			metaTags: [
				{
					name: 'description',
					content: 'How to use collaborative apps in a Google Meet online meeting'
				}
			]
		}
	},
	{
		path: '/resources/Improving_Online_Education_Through_Collaboration',
		name: 'Improving Online Education Through Collaboration',
		html: '/fluff/fluff_improving_online_education.html',
		component: FluffPage,
		meta: {
			title: siteTitle + ' - Improving Online Education Through Collaboration',
			metaTags: [
				{
					name: 'description',
					content: 'Improving Online Education Through Collaboration'
				}
			]
		}
	},
	{
		path: '/resources/How_To_Write_A_Collaborative_App',
		name: 'How To Write a Collaborative App',
		html: '/fluff/fluff_writing_a_collaborative_app.html',
		component: FluffPage,
		meta: {
			title: siteTitle + ' - How To Write a Collaborative App',
			metaTags: [
				{
					name: 'description',
					content: 'How To Write a Collaborative App'
				}
			]
		}
	}
];
