import { eraseCookie, getQueryVariable } from './helpers/helper';
import { http } from './http';
import { state } from './state';

export const base_api = gatheract.apiRoot;
const ltiaas_url = '';
const client_id = '';

let apps_promise: Promise<IAppInfo[]> = null;

class StoreAPI {
	getApps(search: IAppSearch): Promise<IAppInfo[]> {
		if (!apps_promise) {
			apps_promise = http.post(`${base_api}/apps`, search);
		}
		return apps_promise;
	}

	getAppLaunchUrl(app_id: string): Promise<string> {
		return http.post(`${base_api}/open_app`, { app_id });
	}

	getClientUrl(): Promise<string> {
		return http.get(`${base_api}/client_url`);
	}

	async launch(app_id: string) {
		if (state.standalone) {
			let url = await http.post(`${base_api}/open_app`, { app_id });
			if (url) {
				document.location.assign(url);
			}
		} else {
			http.post(`${base_api}/open_app`, { clientid: client_id, app_id });
		}
	}

	async deepLink(module: IAppInfo) {
		let app_id = module.id;
		const ltik = getQueryVariable('ltik');
		return http.post(`${base_api}/link_app`, { app_id, ltik }).then(response => {
			document.write(response.form);
		});
	}

	createdAccount(form: ICreateAcount): Promise<IloginResponse> {
		return http.post(`${base_api}/create_account`, form);
	}

	sendResetCode(email: string): Promise<ISendResetCodeResponse> {
		return http.post(`${base_api}/send_reset_code`, { email });
	}

	login(form: ILogin): Promise<IloginResponse> {
		return http.post(`${base_api}/login`, form);
	}

	myApps(): Promise<IAppInfo[]> {
		return http.get(`${base_api}/user_apps`, { user: state.luser });
	}

	getApp(id: string): Promise<IAppInfo> {
		return http.get(`${base_api}/app/${id}`);
	}

	getLtiPlatform(clientId: string): Promise<ILTIForm> {
		return http.get(`${ltiaas_url}/api/platforms/${clientId}`);
	}

	deleteLtiPlatform(clientId: string): Promise<ILTIForm> {
		return http.del(`${ltiaas_url}/api/platforms/${clientId}`);
	}

	createLtiPlatform(form: ILTIForm): Promise<string> {
		return http.post(`${ltiaas_url}/api/platforms`, form);
	}

	updateLtiPlatform(clientId: string, form: ILTIForm): Promise<string> {
		return http.put(`${ltiaas_url}/api/platforms/${clientId}`, form);
	}

	getLtiPlatforms(): Promise<ILTIForm[]> {
		return http.get(`${ltiaas_url}/api/platforms`);
	}

	devApps(): Promise<IAppInfo[]> {
		return http.get(`${base_api}/dev_apps`, { user: state.luser });
	}

	getDevVClient(): Promise<IVClient> {
		return http.get(`${base_api}/dev_vclient`, { user: state.luser });
	}

	createApp(form: IAppInfo): Promise<IAppInfo> {
		return http.post(`${base_api}/create_app`, form, { user: state.luser });
	}

	createSuggestion(form: ISuggestion) {
		return http.post(`${base_api}/create_suggestion`, form);
	}

	getSuggestions(): Promise<ISuggestion[]> {
		return http.get(`${base_api}/suggestions`);
	}

	updateApp(form: IAppInfo) {
		return http.post(`${base_api}/update_app`, form, { user: state.luser });
	}

	async logout() {
		http.get(`${base_api}/logout/${state.luser.session_id}`);
		localStorage.removeItem('user');
		eraseCookie('session_id');
		//state.luser = null;
		state.luser.session_id = state.luser.name = state.luser.email = undefined;
	}

	uploadPackage(app_id: string, file: File): XMLHttpRequest {
		let url = `${base_api}/upload_app/${app_id}`;
		let formData = new FormData();
		formData.append('package', file);
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url);
		xhr.setRequestHeader('auth_token', state.luser.session_id);
		xhr.send(formData);
		return xhr;
	}

	uploadAppAssets(app_id: string, files: File[]) {
		let url = `${base_api}/app_assets/${app_id}`;
		let formData = new FormData();
		files.forEach(f => {
			formData.append(f.name, f);
		});

		const xhr = new XMLHttpRequest();
		xhr.open('POST', url);
		xhr.setRequestHeader('auth_token', state.luser.session_id);
		xhr.send(formData);
		return xhr;
	}

	downloadPackage(app_id: string) {
		let iframe = document.querySelector('iframe');
		if (!iframe) {
			iframe = document.createElement('iframe');
			iframe.style.display = 'none';
			document.body.appendChild(iframe);
		}
		iframe.src = `${base_api}/download_app/${app_id}`;
	}

	deleteApp(id: string) {
		return http.get(`${base_api}/delete_app/${id}`, { user: state.luser });
	}

	deleteVClient(id: string) {
		return http.get(`${base_api}/delete_vclient/${id}`);
	}

	updateAccount(form: IAccountForm): Promise<IloginResponse> {
		return http.post(`${base_api}/update_account`, form, { user: state.luser });
	}

	getUniqueChannelName() {
		return http.get(`${base_api}/unique_channel_name`);
	}

	deployCoreApp(name: string) {
		return http.get(`${base_api}/deploy_core_app/${name}`);
	}

	getCoreApps() {
		return http.get(`${base_api}/core_apps`, { user: state.luser });
	}

	getServerStats() {
		return http.get(`${base_api}/server_stats`);
	}
}

export const store_api = new StoreAPI();
