export const FocusDirective = {
	// When the bound element is inserted into the DOM...
	inserted: function (el) {
		// Focus the element
		el.focus();
	},
	updated: () => {}
};

export function focusRef() {
	const ref = Vue.ref(null);
	Vue.watch(ref, () => {
		let el = ref.value;
		if (el) el.focus();
	});
	return ref;
}
