import { Sidebar } from '../components/sidebar';
import { ThinSidebar } from '../components/thinSidebar';
import { Component, onIframeLoad } from '../helpers/helper';
import { state } from '../state';

export const Channel = Component(() => {
	function toggleCaptions() {
		api.send({
			type: 'captions_enable',
			enable: !state.captions_enabled
		});
	}

	function toggleSpeech() {
		state.speech_enabled = !state.speech_enabled;
		if (state.speech_enabled) {
			let translateScript = document.createElement('script');
			translateScript.setAttribute(
				'src',
				'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
			);
			document.head.appendChild(translateScript);
		}
	}

	return () => (
		<div id="channel">
			<ThinSidebar></ThinSidebar>
			{state.expanded && <Sidebar></Sidebar>}
			<div id="main_content">
				{state.showVideo && (
					<div id="videoContainer">
						{state.mainVideoStream && (
							<video class={{ flip: state.mirrorVideo }} srcObject={state.mainVideoStream} autoplay></video>
						)}
					</div>
				)}

				{/* {state.browsingApps && <iframe onLoad={onIframeLoad} id="storeFrame" src={state.storeUrl}></iframe>} */}

				<iframe
					onLoad={onIframeLoad}
					id="app-frame"
					src={state.appUrl}
					style={{ display: state.showApp ? 'block' : 'none' }}
					allow="fullscreen ; microphone ; camera ; geolocation ; midi ; encrypted-media ; autoplay "
				></iframe>

				{state.loadingChannel && <div id="noAppLoadedMessage">Loading Channel..</div>}

				{!state.loadingChannel && !state.showApp && !state.mainVideoStream && (
					<div id="noAppLoadedMessage">
						<a onClick={e => state.browseApps()}>Select App</a>
					</div>
				)}
			</div>
		</div>
	);
});
