import { state } from './state';
class closedCaptions {
	recognition = null;
	lastPacketFinished: ICaptionPart[] = [];
	cullTimeout = null;
	running = false;
	caption_record: string[] = [];
	observer = null;

	async init(): Promise<void> {
		if (!this.running && state.captions_enabled) {
			//@ts-ignore
			const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

			try {
				console.log('init');
				this.recognition = new SpeechRecognition();

				this.recognition.continuous = true;
				this.recognition.interimResults = false;
				let sel: HTMLSelectElement = document.querySelector('select.goog-te-combo');
				this.recognition.lang = sel ? sel.value : 'en';
				this.recognition.addEventListener('result', this.onResult.bind(this));
				this.recognition.addEventListener('end', this.init.bind(this));
				this.recognition.addEventListener('error', this.restart.bind(this));
				this.recognition.addEventListener('error', console.log);
				//this.recognition.addEventListener('nomatch', console.log);
				//this.recognition.addEventListener('soundend', console.log);
				this.recognition.addEventListener('audiodend', this.restart.bind(this));
				//this.recognition.addEventListener('onspeechend', console.log);

				this.cullTimeout = setInterval(this.cullCaptions, 1000);

				this.start();
				this.running = true;

				// keep checking the DOM for translator changes
				if (this.observer) {
					clearInterval(this.observer);
				}
				this.observer = setInterval(this.translationObserver.bind(this), 1000);
			} catch (e) {}
		}
	}

	translationObserver() {
		let target = document.querySelector('#closed_caption');
		let items = [];

		target.childNodes.forEach(function (value, key, parent) {
			items.push(value.textContent);
		});

		for (let i = 0; i < items.length; i++) {
			let text = items[i];
			if (!state.caption_queue.find(t => t.name + ': ' + t.text == text) && !this.caption_record.find(t => t == text)) {
				this.caption_record.push(text);
				//speak it!
				if (state.speech_enabled) {
					let msg = new SpeechSynthesisUtterance(text);
					let sel: HTMLSelectElement = document.querySelector('select.goog-te-combo');
					msg.lang = sel.value;
					window.speechSynthesis.speak(msg);
				}
			}
		}

		if (this.caption_record.length > 10) {
			//pop to keep record managable
			this.caption_record.splice(this.caption_record.length - 1, 1);
		}
	}

	cullCaptions() {
		const now = new Date();
		let time = now.getTime() - 5000;
		state.caption_queue = state.caption_queue.filter(item => item.time > time);
	}

	stop() {
		this.running = false;
		this.recognition.stop();
	}

	restart() {
		this.stop();
		this.init();
		console.log('restarting');
	}

	stopForever() {
		this.running = false;
		this.recognition.stop();
		clearInterval(this.observer);
	}

	start() {
		this.recognition.start();
	}

	addMessage(packet: ICaptionPart) {
		clearInterval(this.cullTimeout);

		if (this.lastPacketFinished[packet.from]) {
			let idx = -1;
			for (let i = state.caption_queue.length - 1; i >= 0; i--) {
				if (state.caption_queue[i].from == packet.from) {
					idx = i;
					break;
				}
			}
			if (idx >= 0 && state.caption_queue[idx].text == packet.text) {
				//do nothing
			} else {
				//console.log("pushing")
				state.caption_queue.push(packet);
				this.lastPacketFinished[packet.from] = packet.finished;
			}
		} else {
			let idx = -1;
			for (let i = state.caption_queue.length - 1; i >= 0; i--) {
				if (state.caption_queue[i].from == packet.from) {
					idx = i;
					break;
				}
			}
			if (idx >= 0) {
				//console.log("replacing: " + idx)
				console.log(state.caption_queue);
				state.caption_queue.splice(idx, 1, packet);
				console.log(state.caption_queue);
			} else {
				//console.log("pushing...")
				state.caption_queue.push(packet);
			}
			this.lastPacketFinished[packet.from] = packet.finished;
		}
		if (packet.finished) {
			this.stop();
			this.cullTimeout = setInterval(this.cullCaptions, 1000);
		}
	}

	onResult(event) {
		for (const res of event.results) {
			console.log(res);
			const now = new Date();
			let packet: ICaptionPart = {
				from: state.user.id,
				name: state.user.name,
				time: now.getTime(),
				text: res[0].transcript,
				finished: res.isFinal
			};
			packet.text.trim();

			api.caption(packet.text, packet.finished);
		}
	}
}

// needs to be at root of DOM for Magic to work
function googleTranslateElementInit() {
	//@ts-ignore
	new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
}

interface ICaptionPart {
	from: string;
	text: string;
	time: number;
	name: string;
	finished: boolean;
}

export const captions = new closedCaptions();
