import { Component } from '../helpers/helper';
import { router } from '../router';
import { store_url } from '../state';
import { base_api, store_api } from '../storeAPI';

class AdminState {
	error_log_url = `${base_api}/log.txt`;
	coreApps = [];
	stats: IServerStats = null;
	suggestions: ISuggestion[] = [];

	async init() {
		store_api.getCoreApps().then(r => (this.coreApps = r || []));
		store_api.getSuggestions().then(r => (this.suggestions = r));
		this.refreshStats();
	}

	clearErrorLog() {
		fetch(`${base_api}/nuke_log`);
	}

	deploy(name: string) {
		store_api.deployCoreApp(name);
	}

	refreshStats() {
		store_api.getServerStats().then(stats => {
			this.stats = stats;
		});
	}

	async cleanOldChannels() {
		await fetch(`${base_api}/clean-old-channels`);
		this.refreshStats();
	}
}

export const AdminPage = Component(() => {
	const l = Vue.reactive(new AdminState());
	l.init();

	return () => (
		<div class="store-page admin" style={{ background: 'white' }}>
			<h1>Admin Functions</h1>
			<div id="buttons">
				<a class="button" onClick={e => l.cleanOldChannels()}>
					Clean Old Channels
				</a>
				<a class="button" href={base_api + '/deploy'} target="_blank">
					Deploy
				</a>
			</div>
			<div id="buttons">
				<a
					class="button"
					onClick={e => {
						router.push('/metrics');
					}}
				>
					Metrics
				</a>
				<a class="button" href={store_url + '/logs/error.txt'} target="_blank">
					Error Log
				</a>
				<a class="button" onClick={e => l.clearErrorLog()}>
					Clear Error Log
				</a>
				<a class="button" href={store_url + '/logs/deploy.log'} target="_blank">
					Deploy Log
				</a>
			</div>
			<h3 style="margin-top: 1em">Stats:</h3>
			<a onClick={e => l.refreshStats()}>[Refresh]</a>
			{l.stats && (
				<div>
					<table>
						<tr>
							<td>user sockets:</td>
							<td>{l.stats.user_sockets}</td>
						</tr>
						<tr>
							<td>app sockets:</td>
							<td>
								{Object.keys(l.stats.app_sockets).map(key => (
									<div key="key">
										{key}:&nbsp;{l.stats.app_sockets[key]}
									</div>
								))}
							</td>
						</tr>
						<tr>
							<td>channels:</td>
							<td>
								{l.stats.channels.map(c => (
									<div key="c.name" style="display: flex; margin-bottom: 0.25em">
										<span class="channel">
											name: {c.name}&nbsp;|&nbsp;vclient: {c.vclient}&nbsp;|&nbsp;users: {c.users}&nbsp;|&nbsp;apps:
											{c.apps.map(app => (
												<span>{app}</span>
											))}
										</span>
									</div>
								))}
							</td>
						</tr>
					</table>
				</div>
			)}
			<h3 style="margin-top: 1em">Suggestions:</h3>
			{l.suggestions.map(s => (
				<div key="s.id">
					<span>
						{s.name} [{s.email}] - {s.date}
					</span>
					<code>{s.message}</code>
				</div>
			))}
			<h3 style="margin-top: 1em">Core Apps</h3>
			<div id="core-apps">
				{l.coreApps.map(app => (
					<div>
						<button onClick={e => l.deploy(app)}>deploy</button>
						{app}
					</div>
				))}
			</div>
		</div>
	);
});
