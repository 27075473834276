import { http } from './http';

export const base_api = gatheract.apiRoot;

class Service {
	constructor() {
		let host = document.location.host;
		let parts = host.split('.');
		if (parts.length > 2) {
			host = `${parts[1]}.${parts[2]}`;
		}
	}

	getUniqueChannelName(): Promise<string> {
		return http.get(`${base_api}/unique_channel_name`).then(r => r.name);
	}

	joinChannel(form: IJoinChannel): Promise<any> {
		return http.post(`${base_api}/join_channel`, form);
	}

	logout(session_id) {
		return http.get(`${base_api}/logout/${session_id}`);
	}

	createAccount(form: ICreateAcount): Promise<IloginResponse> {
		return http.post(`${base_api}/create_account`, form);
	}

	sendPasswordResetCode(email: string): Promise<IloginResponse> {
		let form = { email };
		return http.post(`${base_api}/send_reset_code`, form);
	}

	resetPassword(email: string, password: string, pw_reset_code: string): Promise<IloginResponse> {
		let form = {
			email,
			password,
			pw_reset_code
		};
		return http.post(`${base_api}/login`, form);
	}

	/*getAppInfo(appId: string): Promise<IAppInfo> {
		return http.get(`${base_api}/app/${appId}`);
	}

	getApps(): Promise<IAppInfo[]> {
		return http.post(`${base_api}/apps`, {});
	}*/

	createSuggestion(form: ISuggestion) {
		return http.post(`${base_api}/create_suggestion`, form);
	}

	getMetrics(days: number): Promise<IMetric[]> {
		return http.get(base_api + '/metrics?days=' + days);
	}
}

export const service = new Service();
