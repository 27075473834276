import { Component } from '../helpers/helper';
import { Icon } from './icon';

const load_state = Vue.reactive({
	message: '',
	active: false
});

const MIN_SHOW_TIME = 1000;

export const Loading = Component(() => {
	let value: boolean;
	let disabled: boolean;
	let state = load_state;

	function toggle() {
		if (disabled) return;
		//this.$emit('input', !value);
	}

	return () =>
		state.active ? (
			<div id="loading">
				<div class="inner">
					<Icon name="upload" />
					{state.message}
				</div>
			</div>
		) : (
			<div></div>
		);
});

export function setLoading(message: string, promise: Promise<any>) {
	let startTime = new Date();
	load_state.message = message;
	load_state.active = true;
	promise.finally(() => {
		let endTime = new Date();
		var diff = endTime.getTime() - startTime.getTime();
		let ms = MIN_SHOW_TIME - diff;
		setTimeout(() => {
			load_state.active = false;
		}, ms);
	});
}
