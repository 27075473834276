import { RouterLink } from 'vue-router';
import { Component } from '../helpers/helper';
import { state } from '../state';
import { Icon } from './icon';
import { store_api } from '../storeAPI';
import { router } from '../router';

export const Navbar = Component(() => {
	return () => (
		<div id="navbar">
			<span class="flex center">
				<Icon name="ga-logo" size="2.5rem" class="ga-logo" />
				<span style="font-size: 1.4em; font-weight: 600">GatherAct</span>
			</span>

			<RouterLink to={'/'}>{() => (state.hasChannel ? 'Back to Meeting' : 'Start Meeting')}</RouterLink>
			<RouterLink to={'/apps'}>{() => 'Apps'}</RouterLink>
			{state.logged_in && <RouterLink to={'/dashboard'}>{() => 'Dashboard'}</RouterLink>}
			<RouterLink to={'/create'}>{() => 'Create'}</RouterLink>
			<RouterLink to={'/about'}>{() => 'About'}</RouterLink>
			<div class="grow" />
			{state.luser.is_admin && <RouterLink to={'/admin'}>{() => 'Admin'}</RouterLink>}
			{state.luser.is_admin && <RouterLink to={'/metrics'}>{() => 'Metrics'}</RouterLink>}
			{!state.logged_in && (
				<RouterLink to="/login">
					{() => (
						<div class="flex center" style={{ color: '#555' }}>
							<Icon name="login" size="20px" />
							<span style="margin-left: 6px">Sign In</span>
						</div>
					)}
				</RouterLink>
			)}
			{state.logged_in && (
				<a
					onClick={e => {
						store_api.logout();
						router.replace('/');
					}}
				>
					Sign out
				</a>
			)}
			{state.logged_in && <RouterLink to="/account">{() => state.luser.name}</RouterLink>}
		</div>
	);
});
