import { Component } from '../helpers/helper';
import { state, User } from '../state';
import { webRTC } from '../webRTC';
import { Icon } from './icon';

const SidebarMenuItem = Component((p: { user: User; icon?: string }) => {
	return () => (
		<div class="sidebar_menu_item">
			<div class={{ 'user-item-name': true, 'video-enabled': p.user.showStream }}>
				<span
					id={`circle-${p.user.me ? 'me' : p.user.id}`}
					class="user_indicator"
					style={{ backgroundColor: p.user.color }}
				></span>
				<span class="sidebar_menu_item_label">{p.user.name}</span>
				{!p.user.muted && (
					<span class="mute_notification">
						<Icon name="microphone" size="12px" />
					</span>
				)}
				{p.user.muted && (
					<span class="mute_notification">
						<Icon name="microphone-slash" size="12px" />
					</span>
				)}
			</div>
			{p.user.showStream && (
				<video
					class={{ flip: p.user.me && state.sharingCam }}
					srcObject={p.user.stream}
					autoplay
					onClick={e => state.setMainVideo(p.user.stream)}
				></video>
			)}
		</div>
	);
});

const Thread = Component((p: { t: IThread }) => {
	return () => (
		<div class="chat_thread">
			<h4 class={{ chat_from: true, 'is-gatheract': p.t.from === '[channel]' }}>
				{p.t.from === '[channel]' ? 'GatherAct' : p.t.from}:
			</h4>
			{p.t.messages.map((m, j) => (
				<div key={j} class="chat_text">
					{m.url && (
						<a target="_blank" href={m.url}>
							{m.text}
						</a>
					)}
					{!m.url && <span>{m.text}</span>}
				</div>
			))}
		</div>
	);
});

const ChatInput = Component(() => {
	function onInput(e) {
		state.newChatMessage = e.target.value;
		if (state.newChatMessage.includes('\n')) {
			let msg = state.newChatMessage;
			state.newChatMessage = null;
			api.postMessage(msg);
		}
	}

	return () => (
		<div class="chat_input">
			<textarea rows="3" value={state.newChatMessage} onInput={onInput} placeholder="Enter message" />
		</div>
	);
});

const SidebarChat = Component(() => {
	const elmessages = Vue.ref(null);

	Vue.watch([state.messages], () => {
		if (elmessages) {
			elmessages.value.scrollTop = elmessages.value.scrollHeight;
		}
	});

	return () => (
		<div class="sidebar_chat">
			<div class="sidebar_header">
				<Icon name="chat-alt-2" size="20px" />
				<h3>Chat</h3>
			</div>
			<div class="chat_messages" ref={elmessages}>
				{state.messageThreads.map((t, i) => (
					<Thread key={i} t={t} />
				))}
			</div>
			<ChatInput />
		</div>
	);
});

export const Sidebar = Component(() => {
	return () => (
		<div id="main_sidebar">
			<div class="sidebar_users">
				{state.appLoaded && state.mainVideoStream && (
					<img id="app-image" src={state.appImageUrl} onClick={e => state.setMainVideo(null)} />
				)}
				{state.users.map(u => (
					<SidebarMenuItem key={u.id} user={u}></SidebarMenuItem>
				))}
			</div>
			<div class="sidebar_menu_divider"></div>
			<SidebarChat />
		</div>
	);
});
