import { Icon } from './icon';
import { Component } from '../helpers/helper';

const acceptTypes: Array<Array<string>> = [
	['.png', 'image/png'],
	['.jpg', 'image/jpeg'],
	['.jpeg', 'image/jpeg'],
	['.gif', 'image/gif'],
	['.zip', 'application/zip']
];

class LocalState {
	draggedOver = false;
	accept: string;
	input: (files: File[]) => void;

	onDragEnter(e: DragEvent) {
		this.draggedOver = true;
		this.preventDefaults(e);
	}

	onDragOver(e: DragEvent) {
		this.draggedOver = true;
		this.preventDefaults(e);
	}

	onDragLeave(e: DragEvent) {
		this.draggedOver = false;
		this.preventDefaults(e);
	}

	onDrop(e: DragEvent) {
		const dt = e.dataTransfer;
		const files = this.validateFiles([...dt.files]);
		console.log(dt.files, files);
		this.input(files);
		this.draggedOver = false;
		this.preventDefaults(e);
	}

	onInputChange(e: InputEvent) {
		const files = (e.target as HTMLInputElement).files;
		console.log(files);
		this.input([...files]);
	}

	preventDefaults(e: Event) {
		e.preventDefault();
		e.stopPropagation();
	}

	validateFiles(files: Array<File>) {
		const accepts = this.accept.split(',').map((a: string) => a.trim());
		return files.filter(f => acceptTypes.find(([accept, type]) => accepts.includes(accept) && f.type === type));
	}
}

export const Upload = Component(
	(p: { accept?: string; tip?: string; multiple?: boolean; input: (file: File[]) => void }, c) => {
		const input = Vue.ref(null);
		let accept = p.accept;
		if (!accept) accept = '.png, .jpg, .jpeg, .gif';
		//if (!p.tip) p.tip = 'PNG, JPG, GIF up to 10MB';
		const el = Vue.ref(null);

		const l = Vue.reactive(new LocalState());
		l.accept = accept;
		l.input = p.input;

		Vue.onMounted(() => {
			input.value.classList.add('upload_input');
		});

		return () => (
			<div
				class={{ dragged_over: l.draggedOver, upload_container: true }}
				onDragenter={e => l.onDragEnter(e)}
				onDragover={e => l.onDragOver(e)}
				onDragleave={e => l.onDragLeave(e)}
				onDrop={e => l.onDrop(e)}
				onClick={e => {
					input.value.click();
				}}
			>
				<div class="upload_content_wrapper">
					{c.slots?.default ? (
						c.slots.default()
					) : (
						<slot>
							<div class="upload_content">
								<Icon name="upload" />
								<p class="upload_directions">
									<span class="upload_link">Upload a file</span>
									<br />
									or drag and drop
								</p>
								<div class="upload_tip">
									<slot name="tip">
										<p v-if="tip">{p.tip}</p>
									</slot>
								</div>
							</div>
						</slot>
					)}
				</div>
				<input ref={input} onChange={e => l.onInputChange(e)} type="file" accept={accept} />
			</div>
		);
	}
);
