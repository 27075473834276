import { DefineSetupFnComponent } from "vue";

interface IDlg extends HTMLElement {
	resolve?: (data: any) => void;
	value?: IDlg;
	vm?: any;
}

class Dlg {
	open<T>(comp: DefineSetupFnComponent<T>, props: T): Promise<any> {
		return new Promise((resolve, reject) => {
			let temp: any = document.createElement('div');
			document.body.appendChild(temp);

			const App = {
				render() {
					//@ts-ignore
					return Vue.h(comp, props);
				}
			};

			const vm = Vue.createApp(App);
			vm.mount(temp);
			temp.vm = vm;
			temp.resolve = resolve;
		});
	}

	async close(el: IDlg|any, data?: any, fadeout = true) {
		if (el.value) el = el.value;
		if (!el.resolve) el = el.parentElement;

		el.resolve(data);
		if (fadeout) {
			el.classList.add('fadeout');
			await new Promise<void>(resolve => {
				el.addEventListener('animationend', () => resolve());
			});
		}

		if (!el.tagName) el = el.value;
		if (!el.vm) el = el.parentElement;

		el.vm.unmount();
		el.remove();
	}
}

export const dlg = new Dlg();
