import { base_api } from './service';

/*interface IMetric {
	user: string;
	location: string;
	seconds: number;
	ad: string;
	info: ILabMetrics;
}*/

function getSeconds(start: Date, end: Date) {
	let time = end.getTime() - start.getTime();
	return Math.round(time / 1000);
}

class Metrics {
	appMetrics: IAppMetric[] = [];
	currentApp: IAppMetric;
	enterDate: Date = new Date();
	user: string;
	channels: string[] = [];
	location = document.location.href;
	referer: string = undefined;

	constructor() {}

	startApp(name: string) {
		if (this.currentApp) {
			this.endApp();
		}

		this.currentApp = {
			startDate: new Date(),
			seconds: 0,
			name
		};

		this.appMetrics.push(this.currentApp);
	}

	endApp() {
		if (!this.currentApp) return;

		try {
			let start = <Date>this.currentApp.startDate;
			this.currentApp.startDate = start.toLocaleString();
			this.currentApp.seconds = getSeconds(start, new Date());
		} catch {}
		this.currentApp = null;
	}
}

export const metrics = new Metrics();

window.addEventListener('beforeunload', sendMetrics);

function sendMetrics() {
	window.removeEventListener('beforeunload', sendMetrics);

	if (metrics.currentApp) {
		metrics.endApp();
	}

	const data: IMetric = {
		id: undefined,
		date: undefined,
		geo: undefined,
		user: metrics.user,
		seconds: getSeconds(metrics.enterDate, new Date()),
		location: metrics.location,
		ad: null,
		referer: metrics.referer,
		info: {
			apps: metrics.appMetrics,
			channels: metrics.channels
		}
	};

	let config: RequestInit = {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data),
		keepalive: true
	};

	fetch(`${base_api}/metrics`, config);
}
