import { metrics } from './metrics';
import { webRTC, local_media_stream } from './webRTC';
import { service } from './service';
import { eraseCookie, getCookie, getQueryVariable, getRandomInt } from './helpers/helper';
import { store_api } from './storeAPI';
import { router } from './router';
import { featured_apps } from './featured_apps';

export class User {
	id: string;
	name: string;
	me: boolean;
	color: string;
	enabled: boolean = false;
	stream: MediaStream = null;
	muted: boolean = true;

	get showStream() {
		return this.stream && this.stream != state.mainVideoStream;
	}
}

export const store_url = gatheract.apiRoot.replace('/api', ''); //''; // 'https://gatheract.com/';

const colors = [
	'aqua',
	'red',
	'yellow',
	'green',
	'magenta',
	'pink',
	'blue',
	'Maroon',
	'Aquamarine',
	'DarkMagenta',
	'DeepPink',
	'IndianRed',
	'YellowGreen',
	'Cyan',
	'SkyBlue',
	'Goldenrod',
	'SandyBrown',
	'MidnightBlue',
	'Gold',
	'Indigo',
	'Navy',
	'Coral',
	'Tomato',
	'Olive',
	'SpringGreen'
];

export const default_channel_id = 'channel_abc';

export let loginPromise: Promise<IloginResponse> = null;

class State {
	newChatMessage: string = null;
	apiInitPromise = Promise.resolve();

	appsLoadedPromise = Promise.resolve();

	//showJoinDlg = true;
	showShareDlg = false;
	showSupportDlg = false;
	showSettingsDlg = false;
	browsingApps = false;

	connected = false;
	messages: ITextMessage[] = [];
	users: User[] = [];
	user: IUser; // this is the channel user
	appUrl: string = null;
	storeUrl: string = null;
	isHost = false;
	micMuted = true;
	audioEnabled = true;
	expanded = true;
	channel_id: string = null;
	app_id: string = null;
	appName: string = null;
	caption_queue: ICaptionPart[] = [];
	captions_enabled: boolean;
	speech_enabled: boolean;

	firstTimeUser: boolean = true;

	appImageUrl = null;
	appList: IAppInfo[] = [];
	appInfo: IAppInfo = null;

	featured_apps: IAppInfo[] = [];

	sharingScreen = false;
	sharingCam = false;
	mainVideoStream: MediaStream = null;

	// luser is the logged in user
	luser: ILocalUser = {
		name: null,
		password: null,
		email: null,
		session_id: null
	};

	gatherActUrl = api.apiRoot.replace('/api', '');
	loadingChannel = false;
	standalone = false;

	get mirrorVideo() {
		return this.sharingCam && this.mainVideoStream == this.users[0].stream;
	}

	get logged_in() {
		return !!this.luser?.session_id;
	}

	setUser(r: IloginResponse) {
		if (!r.success) {
			store_api.logout();
			return;
		}

		this.luser = {
			id: undefined,
			email: r.email,
			name: r.name || null,
			session_id: r.session_id,
			is_admin: r.is_admin,
			is_dev: r.is_dev,
			secret: r.secret || null
		};
	}

	toggleSidebar() {
		this.expanded = !this.expanded;
		localStorage.setItem('expanded', `${this.expanded}`);
	}

	stopVideoTrack(vstream: MediaStream) {
		this.users.forEach(u => {
			if (u.stream === vstream) {
				u.stream = null;
			}
		});

		if (this.mainVideoStream === vstream) {
			this.setMainVideo(null);
		}
	}

	addVideoStream(stream: MediaStream, userId: string) {
		let u = state.users.find(u => u.id === userId);
		if (u) u.stream = stream;
		this.setMainVideo(stream);
	}

	setMainVideo(stream: MediaStream) {
		this.mainVideoStream = stream;
	}

	removeQueryFromUrl() {
		let newUrl = document.location.href.replace(document.location.search, '');
		window.history.replaceState(null, document.title, newUrl);
	}

	reset() {
		this.messages.length = 0;
	}

	get hasAudio(): boolean {
		return !!local_media_stream;
	}

	get hasChannel(): boolean {
		return !!this.channel_id?.trim();
	}

	get appLoaded() {
		return !!this.appUrl;
	}

	get showVideo() {
		return !this.browsingApps && this.mainVideoStream;
	}

	get showApp(): boolean {
		return !this.browsingApps && !!this.appUrl && !this.mainVideoStream;
	}

	get messageThreads(): IThread[] {
		const result = [];
		let lastThread = null;
		this.messages.forEach(message => {
			if (!lastThread || lastThread.from !== message.from) {
				lastThread = {
					from: message.from,
					messages: [message]
				};
				result.push(lastThread);
			} else {
				lastThread.messages.push(message);
			}
		});
		return result;
	}

	async init() {
		let session_email = getCookie('session_email');
		if (session_email) {
			this.luser.session_id = getCookie('session_id');
			this.luser.email = session_email;
		}
		let session_id = getCookie('session_id');
		if (session_id) {
			loginPromise = store_api.login({ session_id: session_id }).then(r => {
				this.setUser(r);
				return r;
			});
		}

		store_api.getApps({}).then(r => {
			this.appList = r;
			this.featured_apps = featured_apps;
		});

		if (document.location.search) {
			this.app_id = getQueryVariable('app');
			this.luser.session_id = getQueryVariable('session');
		}

		let expand = JSON.parse(localStorage.getItem('expanded'));
		if (expand !== null) {
			this.expanded = expand;
		}
	}

	async hangup() {
		api.leaveChannel();
		webRTC.closeAllConnections();

		this.micMuted = true;
		this.storeUrl = null;
		this.channel_id = null;
		this.users = [];
		this.messages = [];
		this.appUrl = null;
		this.sharingScreen = false;
		this.appName = null;

		this.browsingApps = false;
		//this.showJoinDlg = true;
		this.showShareDlg = false;
		this.showSupportDlg = false;
		this.showSettingsDlg = false;

		router.push('/');
	}

	closeApp(sendMessage = true) {
		metrics.endApp();
		this.appUrl = '';
		this.appName = '';
		if (sendMessage) {
			api.send({ type: 'close_app' });
		}
	}

	enableMic(enabled: boolean) {
		this.micMuted = !enabled;
		webRTC.enableMic(enabled);
		api.send({ type: 'muted', muted: this.micMuted, user: this.user });
	}

	enableCam() {
		webRTC.enableCam();
	}

	shareScreen() {
		webRTC.screenShare();
	}

	browseApps() {
		/*if (!this.storeUrl) {
			this.storeUrl = gatheract.storeUrl;
		}*/
		this.browsingApps = true;
	}

	stopBrowsingApps() {
		this.browsingApps = false;
	}

	getColor(): string {
		let result = colors.find(c => !this.users.some(u => u.color === c));
		return result || colors[getRandomInt(0, colors.length - 1)];
	}

	updateUserMutedState(user: IUser, muted: boolean) {
		let foundUser = this.users.find(ur => ur.id === user.id);
		if (foundUser) {
			Vue.nextTick(() => {
				foundUser.muted = muted;
			});
		}
	}

	updateUsers(users: IUser[]) {
		let used = {};
		users.forEach(u => {
			used[u.id] = true;
			let user = this.users.find(ur => ur.id === u.id);
			if (!user) {
				user = new User();
				user.id = u.id;
				user.color = this.getColor();
				user.me = u.id === state.user.id;

				if (user.me) {
					this.users.unshift(user);
				} else {
					this.users.push(user);
				}
			}
			user.name = u.name;
		});

		for (let i = this.users.length - 1; i >= 0; i--) {
			if (used[this.users[i].id] === undefined) {
				this.users.splice(i, 1);
			}
		}
	}
}

export const state = Vue.reactive(new State());
state.init();
//@ts-ignore
window.state = state;
