import { openToast } from './components/toast';
import { state } from './state';
const MUTE_AUDIO_BY_DEFAULT = false;
var ICE_SERVERS = [
	{ urls: ['stun:stun.l.google.com:19302'] },
	{
		urls: ['turn:turn.gatheract.com'],
		username: 'gacoturn',
		credential: 'notpublic'
	}
];

/* our own microphone / webcam */
export let local_media_stream: MediaStream = null;
export let local_media: HTMLAudioElement = null;
export let local_video_stream: MediaStream = null;
export let local_media_promise = Promise.resolve();

let localMeter: SoundMeter = null;
let force_turn = false;

/* keep track of our peer connections, indexed by peer_id (aka socket.io id) */
const peers = new Map<string, Peer>();
const polite = true;

class Peer {
	id: string;
	conn: RTCPeerConnection;
	dc: RTCDataChannel;
	makingOffer = false;
	ignoreOffer = false;
	attempts = 1;
	audio: HTMLAudioElement;
	video: MediaStream;
	meter: SoundMeter;

	constructor(id: string, attempts = 0) {
		this.id = id;

		this.conn = new RTCPeerConnection({
			iceServers: ICE_SERVERS,
			iceTransportPolicy: force_turn ? 'relay' : 'all'
		});

		this.conn.onnegotiationneeded = this.onnegotiationneeded.bind(this);

		this.conn.onicecandidate = event => {
			if (event.candidate) {
				api.send({
					type: 'webrtc_ice_candidate',
					peer_id: state.user.id,
					ice_candidate: event.candidate.toJSON(),
					client_id: id
				});
			}
		};

		this.conn.ontrack = event => {
			let stream = event.streams[0];
			if (event.track.kind === 'audio') {
				if (!this.audio) {
					this.audio = webRTC._createAudioElement(false);
				}

				this.audio.srcObject = stream;
				// if (!this.meter) {
				// 	this.meter = new SoundMeter(this.id, event.streams[0]);
				// } else {
				// 	this.meter.connectToSource(event.streams[0]);
				// }
			} else if (event.track.kind === 'video') {
				this.video = stream;
				state.addVideoStream(this.video, this.id);
			}

			stream.onremovetrack = t => {
				if (event.track.kind == 'audio' && this.audio) {
					this.audio.remove();
					this.audio = null;
				}
				if (event.track.kind == 'video') {
					state.stopVideoTrack(this.video);
					this.video = null;
				}
			};
		};

		this.conn.onconnectionstatechange = event => {
			if ('failed' === this.conn.connectionState) {
				console.log(`${state.user.id}: removing peer (${this.conn.connectionState}): ${this.id}`);
				//this.remove();
			} else if ('disconnected' === this.conn.connectionState) {
				console.log(`${state.user.id}: removing peer (${this.conn.connectionState}): ${this.id}`);
				this.remove();
			}
		};

		this.conn.onicecandidateerror = event => {
			//@ts-ignore
			console.error('onicecandidateerror: ' + event.errorText + ' - ' + event.url);
		};

		this.conn.oniceconnectionstatechange = event => {
			console.log('icestate: ' + this.conn.iceConnectionState);
			//@ts-ignore
			let canRestart = this.conn.restartIce !== undefined && this.attempts < 2;
			let failed = this.conn.iceConnectionState === 'failed';
			if (failed && canRestart) {
				console.error('ice failed, restarting..');
				/* possibly reconfigure the connection in some way here */
				/* then request ICE restart */
				this.attempts++;
				//@ts-ignore
				this.conn.restartIce();
			} else if (failed) {
				console.error('ice failed');
			}
		};
	}

	async onnegotiationneeded() {
		try {
			let pc = this.conn;
			this.makingOffer = true;
			const offer = await pc.createOffer();
			if (pc.signalingState != 'stable') return;
			await pc.setLocalDescription(offer);

			api.send({
				type: 'webrtc_offer',
				peer_id: state.user.id,
				offer: pc.localDescription.toJSON(),
				client_id: this.id
			});
		} catch (e) {
			console.error(`ONN ${e}`);
		} finally {
			this.makingOffer = false;
		}
	}

	async onOfferOrCandidate(description: RTCSessionDescriptionInit, candidate: RTCIceCandidateInit) {
		let pc = this.conn;
		try {
			if (description) {
				const offerCollision =
					description.type == 'offer' && (this.makingOffer || this.conn.signalingState != 'stable');

				this.ignoreOffer = !polite && offerCollision;
				if (this.ignoreOffer) {
					return;
				}
				if (offerCollision) {
					await Promise.all([pc.setLocalDescription({ type: 'rollback' }), pc.setRemoteDescription(description)]);
				} else {
					await pc.setRemoteDescription(description);
				}
				if (description.type == 'offer') {
					await pc.setLocalDescription(await pc.createAnswer());
					api.send({
						type: 'webrtc_answer',
						peer_id: state.user.id,
						answer: pc.localDescription.toJSON(),
						client_id: this.id
					});
				}
			} else if (candidate) {
				try {
					await pc.addIceCandidate(candidate);
				} catch (e) {
					if (!this.ignoreOffer) console.error(e);
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

	remove() {
		this.conn.close();
		let found = peers.get(this.id);
		if (found === this) {
			peers.delete(this.id);
		}

		if (this.audio) this.audio.remove();
		if (this.video) {
			state.stopVideoTrack(this.video);
			this.video = null;
		}
	}
}

class WebRTC {
	/* mic/speaker options */
	audioInputDeviceOptions: ISelectItem[] = [];
	audioInputDeviceId: string = null;

	audioOutputDeviceOptions: ISelectItem[] = [];
	audioOutputDeviceId: string = null;

	videoInputDeviceOptions: ISelectItem[] = [];
	videoInputDeviceId: string = null;

	constructor() {}

	async setAudioOutputDevice(deviceId: string) {
		if (deviceId && deviceId !== this.audioOutputDeviceId) {
			try {
				this.audioOutputDeviceId = deviceId;
				let audioOutputs = document.querySelectorAll('audio');
				audioOutputs.forEach(element => {
					//@ts-ignore
					if (typeof element.sinkId !== 'undefined') {
						if (this.audioOutputDeviceId) {
							//@ts-ignore
							element.setSinkId(this.audioOutputDeviceId);
						}
					} else {
						console.warn('Browser does not support output device selection.');
					}
				});
			} catch (e) {
				this.handleError(e);
			}
		}
	}

	async setVideoInputDevice(deviceId: string) {
		if (deviceId && deviceId !== this.videoInputDeviceId) {
			// Set the input deviceId
			this.videoInputDeviceId = deviceId;
			this.enableCam(true);
		}
	}

	async setAudioInputDevice(deviceId: string) {
		if (deviceId && deviceId !== this.audioInputDeviceId) {
			try {
				if (deviceId == '0') {
					var constraints: MediaStreamConstraints = {
						audio: true,
						video: false
					};
				} else if (local_video_stream) {
					var constraints: MediaStreamConstraints = {
						audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
						video: { deviceId: this.videoInputDeviceId ? { exact: this.videoInputDeviceId } : undefined }
					};
				} else {
					var constraints: MediaStreamConstraints = {
						audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
						video: false
					};
				}

				if (local_media_stream) {
					this._stopSharingTracks(local_media_stream);
					local_media_stream = null;
				}

				local_media_stream = await navigator.mediaDevices.getUserMedia(constraints);

				// Set up level meter
				// if (!localMeter) {
				// 	localMeter = new SoundMeter('me', local_media_stream);
				// }

				if (!local_media) {
					local_media = this._createAudioElement(true);
				}
				local_media.srcObject = local_media_stream;
				this._shareTracks(local_media_stream);

				// Set the input deviceId
				this.audioInputDeviceId = deviceId;
			} catch (e) {
				this.handleError(e);
			}
		}
	}

	_shareTracks(steam: MediaStream) {
		state.users.forEach(u => {
			if (u.me) return;
			let peer = peers.get(u.id);
			if (!peer) {
				peer = this._addPeerConnection(u.id);
			}

			let senders = peer.conn.getSenders();
			steam.getTracks().forEach(t => {
				if (!senders.some(s => s.track === t)) {
					peer.conn.addTrack(t, steam);
				}
			});
		});
	}

	_stopSharingTracks(stream: MediaStream) {
		let tracks = stream.getTracks();
		tracks.forEach(t => t.stop());
		for (let p of peers.values()) {
			let senders = p.conn.getSenders();
			senders.forEach(sender => {
				if (tracks.includes(sender.track)) {
					p.conn.removeTrack(sender);
				}
			});
		}
	}

	/**
	 * When we join a group, our signaling server will send out 'addPeer' events to each pair
	 * of users in the group (creating a fully-connected graph of users, ie if there are 6 people
	 * in the channel you will connect directly to the other 5, so there will be a total of 15
	 * connections in the network).
	 */
	_addPeerConnection(peer_id: string): Peer {
		if (peer_id in peers) {
			/* This could happen if the user joins multiple channels where the other peer is also in. */
			let error = `Already connected to peer ${peer_id}`;
			throw new Error(error);
		}

		let peer = new Peer(peer_id);
		peers.set(peer_id, peer);

		/* Add our local stream */
		if (local_media_stream) {
			local_media_stream.getTracks().forEach(t => peer.conn.addTrack(t, local_media_stream));
		}
		if (local_video_stream) {
			local_video_stream.getTracks().forEach(t => peer.conn.addTrack(t, local_video_stream));
		}

		return peer;
	}

	closeAllConnections() {
		if (local_media_stream) {
			local_media_stream.getTracks().forEach(t => {
				t.stop();
			});
			local_media_stream = null;

			if (local_media) {
				local_media.remove();
				local_media = null;
			}
		}

		this.audioInputDeviceId = null;
		this.audioOutputDeviceId = null;

		for (let p of peers.values()) {
			p.remove();
		}
	}

	startConnection(peer_id: string) {
		try {
			this._addPeerConnection(peer_id);
		} catch (err) {
			console.error(err);
		}
	}

	async setAudioOptions() {
		// Set the audio input/output devices
		const devices = await navigator.mediaDevices.enumerateDevices();
		const inputOptions: ISelectItem[] = [];
		const outputOptions: ISelectItem[] = [];
		const videoOptions: ISelectItem[] = [];

		for (let device of devices) {
			let label = device.label;

			let list: ISelectItem[] = null;
			switch (device.kind) {
				case 'audioinput':
					list = inputOptions;
					if (!label) label = 'microphone';
					break;
				case 'audiooutput':
					list = outputOptions;
					if (!label) label = 'speaker';
					break;
				case 'videoinput':
					list = videoOptions;
					if (!label) label = 'camera';
					break;
			}

			if (!list) return;

			let found = list.some(i => i.name.includes(label));
			if (!found) {
				list.push({
					value: device.deviceId,
					name: label
				});
			}
		}
		this.audioInputDeviceOptions = inputOptions;
		this.audioOutputDeviceOptions = outputOptions;
		this.videoInputDeviceOptions = videoOptions;
	}

	/***********************/
	/** Local media stuff **/
	/***********************/
	async setup_local_media(): Promise<void> {
		if (local_media_stream != null || !navigator.mediaDevices) {
			return;
		}

		try {
			/* Ask user for permission to use the computers microphone and/or camera,
			 * attach it to an <audio> or <video> tag if they give us access. */
			const constraints: MediaStreamConstraints = {
				audio: { deviceId: this.audioInputDeviceId ? { exact: this.audioInputDeviceId } : undefined },
				video: false
			};
			await navigator.mediaDevices.getUserMedia(constraints);

			await this.setAudioOptions();

			// Set the audio input device
			if (this.audioInputDeviceOptions.length) {
				await this.setAudioInputDevice(this.audioInputDeviceOptions[0].value);
			}
			// Set the audio output device
			if (this.audioOutputDeviceOptions.length) {
				await this.setAudioOutputDevice(this.audioOutputDeviceOptions[0].value);
			} else {
				await this.setAudioOutputDevice('0');
			}
		} catch (e) {
			this.handleError(e);
		}
	}

	_createAudioElement(muted: boolean): HTMLAudioElement {
		let container = document.querySelector('#audioContainer');
		let local_media = document.createElement('audio');
		local_media.setAttribute('autoplay', 'autoplay');
		local_media.muted = muted;
		//@ts-ignore
		if (typeof local_media.sinkId !== 'undefined') {
			if (this.audioOutputDeviceId) {
				//@ts-ignore
				local_media.setSinkId(this.audioOutputDeviceId);
			}
		} else {
			console.warn('Browser does not support output device selection.');
		}
		container.append(local_media);
		local_media.removeAttribute('controls');
		return local_media;
	}

	enableMic(enabled: boolean) {
		if (!local_media_stream) {
			if (enabled) {
				webRTC.setup_local_media();
			}
			return;
		}
		local_media_stream.getAudioTracks()[0].enabled = enabled; // or false to mute it.
	}

	enablePeer(peer_id: string, enabled: boolean) {
		let peer = peers.get(peer_id);
		if (peer && peer.audio) {
			peer.audio.muted = !enabled;
		}
	}

	async screenShare() {
		if (local_video_stream) {
			let wasSharing = state.sharingScreen;
			this.stopVideoShare();
			if (wasSharing) return;
		}

		try {
			//@ts-ignore
			local_video_stream = await navigator.mediaDevices.getDisplayMedia();
			state.sharingScreen = true;
			state.addVideoStream(local_video_stream, state.user.id);

			let videoTrack = local_video_stream.getVideoTracks()[0];
			videoTrack.onended = this.stopVideoShare.bind(this);
			this._shareTracks(local_video_stream);
		} catch (err) {
			if (err.code === 0) return;
			alert(err);
		}
	}

	async enableCam(changeSource = false) {
		if (local_video_stream) {
			let wasSharing = state.sharingCam;
			this.stopVideoShare();
			if (wasSharing && !changeSource) return;
		}

		try {
			if (!this.videoInputDeviceId) {
				await this.setAudioOptions(); // Get list of cameras etc.
				if (this.videoInputDeviceOptions.length) {
					this.videoInputDeviceId = this.videoInputDeviceOptions[0].value;
				} else {
					openToast('No Camera Found', true);
					return;
				}
			}
			const constraints: MediaStreamConstraints = {
				audio: false,
				video: { deviceId: this.videoInputDeviceId ? { exact: this.videoInputDeviceId } : undefined }
			};
			local_video_stream = await navigator.mediaDevices.getUserMedia(constraints);
			state.sharingCam = true;
			state.addVideoStream(local_video_stream, state.user.id);

			let videoTrack = local_video_stream.getVideoTracks()[0];
			videoTrack.onended = this.stopVideoShare.bind(this);
			this._shareTracks(local_video_stream);
		} catch (err) {
			alert(err);
		}
	}

	stopVideoShare() {
		let tracks = local_video_stream.getVideoTracks();
		if (tracks.length == 0) return;

		let videoTrack = tracks[0];
		videoTrack.stop();

		this._stopSharingTracks(local_video_stream);

		state.sharingScreen = false;
		state.sharingCam = false;
		state.stopVideoTrack(local_video_stream);
		local_video_stream = null;
	}

	onWebRtcMessage(peer_id: string, desc: RTCSessionDescriptionInit, candidate: RTCIceCandidateInit) {
		let peer = peers.get(peer_id);
		if (!peer) {
			peer = this._addPeerConnection(peer_id);
		}
		peer.onOfferOrCandidate(desc, candidate);
	}

	removePeer(peer_id: string) {
		let p = peers.get(peer_id);
		if (p) p.remove();
	}

	getStats() {
		for (let p of peers.values()) {
			p.conn.getStats().then(r => {
				r.forEach(s => console.log(s));
			});
		}
	}

	handleError(error: Error) {
		console.error('error: ', error.message, error.name);
	}
}

export const webRTC = new WebRTC();

interface ISelectItem {
	value: string;
	name: string;
	//groupId: string;
}
