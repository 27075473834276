import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { AdminPage } from './views/admin';
import { ClientPage } from './views/client';
import { BrowsePage } from './views/browsePage';
import { AppPage } from './views/appPage';
import { LoginPage } from './views/login';
import { AboutPage } from './views/about';
import { CreatePage } from './views/create';
import { Metrics } from './views/metrics';
import { AccountPage } from './views/account';
import { TermsPage } from './views/terms';
import { PrivacyPage } from './views/privacy';
import { Dashboard } from './views/dashboard';
import { EditApp } from './views/editApp';
import { fluffRoutes } from './views/fluff';

const routes = [
	{ path: '/admin', component: AdminPage },
	{ path: '/metrics', component: Metrics },
	{ path: '/dashboard', component: Dashboard },
	{ path: '/app/:name/:id', component: AppPage },
	{ path: '/edit-app/:id', component: EditApp },
	{ path: '/apps', component: BrowsePage, meta: { title: 'Apps' } },
	{ path: '/deeplink', component: BrowsePage, meta: { title: 'Apps' } },
	{ path: '/browse', component: BrowsePage, meta: { title: 'Apps' } },
	{ path: '/about', component: AboutPage, meta: { title: 'About' } },
	{ path: '/login', component: LoginPage },
	{ path: '/terms_of_service', component: TermsPage, meta: { title: 'Terms of Service' } },
	{ path: '/privacy_policy', component: PrivacyPage, meta: { title: 'Privacy Policy' } },
	{ path: '/create', component: CreatePage, meta: { title: 'Create' } },
	{ path: '/account', component: AccountPage },
	{ path: '/:pathMatch(.*)*', component: ClientPage }
];

for (let f of fluffRoutes) {
	routes.push(f);
}

export const router = createRouter({
	history: createWebHistory(),
	routes: routes
});

router.beforeEach(() => {});

router.afterEach((to, from) => {
	if (to.meta?.title) document.title = 'GatherAct - ' + to.meta.title;
	else document.title = 'GatherAct';
});
