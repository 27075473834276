import { Icon } from '../components/icon';
import { focusRef } from '../helpers/focus';
import { Component } from '../helpers/helper';
import { service } from '../service';
import { state } from '../state';

class LState {
	fromName: string = state.user.name;
	email: string = null;
	message: string = null;
	error: string = null;
}

export const SupportDlg = Component(() => {
	const l = Vue.reactive(new LState());

	function close() {
		state.showSupportDlg = false;
	}

	async function send() {
		if (!l.fromName || !l.email || !l.message) {
			l.error = 'Name, Email, and Message are required';
			return;
		}

		let form: ISuggestion = {
			id: null,
			email: l.email,
			name: l.fromName,
			message: l.message,
			date: null
		};
		service.createSuggestion(form);
		close();
	}

	return () => (
		<div class="dlg-wrapper">
			<div id="support-dlg" class="dlg">
				<button class="bt-close" onClick={close}>
					<Icon name="times-solid" />
				</button>
				<div class="header">Need help or have feedback?</div>
				<div class="sub-header">Send us a message</div>

				<div style="text-align: left;">
					<label>Your Name</label>
					<input ref={focusRef()} value={l.fromName} onChange={e => (l.fromName = e.target.value)} />

					<label>Your Email</label>
					<input value={l.email} onChange={e => (l.email = e.target.value)} />

					<label style="margin-top: 10px;">Message</label>
					<textarea value={l.message} onChange={e => (l.message = e.target.value)}></textarea>

					{l.error && <div style="margin-top: 10px; color: yellow;">{l.error}</div>}

					<div style="display:flex;gap:10px;justify-content:flex-end; margin-top:10px">
						<button class="button" style="display: inline" onClick={send}>
							Send
						</button>
						<button class="button" onClick={close}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
});
