import { setLoading } from '../components/loading';
import { Component } from '../helpers/helper';
import { router } from '../router';
import { store_api } from '../storeAPI';
import { Upload } from '../components/upload';
import { focusRef } from '../helpers/focus';
import { state, store_url } from '../state';
import { Icon } from '../components/icon';

const TAGS = [
	'Math',
	'Reading',
	'Writing',
	'Drawing',
	'Geography',
	'Typing',
	'Spelling',
	'Collaborative',
	'Science',
	'Game'
].sort();

class LocalState {
	app: IAppInfo = {
		tags: [],
		images: []
	} as any;
	orig: IAppInfo = null;
	files: File[] = [];
	tileFile: File = null;
	error: string = null;
	removed = false;
	tags: string[] = TAGS;
	showTagMenu = false;

	get activeTags(): string[] {
		return this.app.tags;
	}

	get pendingChanges(): boolean {
		if (!this.app.id || this.files.length || this.tileFile) return true;

		return (
			this.app.name !== this.orig.name ||
			this.app.description !== this.orig.description ||
			this.app.long_desc !== this.orig.long_desc ||
			this.app.version !== this.orig.version ||
			this.app.price !== this.orig.price ||
			this.app.published !== this.orig.published ||
			this.app.launch_url !== this.orig.launch_url ||
			this.app.is_rtc !== this.orig.is_rtc ||
			this.app.is_lti !== this.orig.is_lti ||
			this.app.images.length !== this.orig.images.length ||
			this.app.tags.some(t => !this.orig.tags.includes(t)) ||
			this.orig.tags.some(t => !this.app.tags.includes(t))
		);
	}

	async init() {
		let id = router.currentRoute.value.params.id as string;
		if (id === 'new') id = null;

		if (id) {
			this.app = await store_api.getApp(id);
			this.setOriginal();
		} else {
			this.app = {
				id: null,
				name: null,
				description: null,
				version: null,
				launch_url: null,
				images: [],
				long_desc: null,
				tile_image: null,
				is_lti: false,
				is_rtc: false,
				published: false,
				price: 0,
				tags: [],
				api_key: undefined
			};
		}
	}

	addTag(tag: string) {
		this.showTagMenu = false;
		if (!this.activeTags.includes(tag)) {
			this.activeTags.push(tag);
		}
	}

	removeTag(tag: string) {
		this.activeTags.splice(this.activeTags.indexOf(tag), 1);
	}

	removeImage(index: number) {
		this.app.images.splice(index, 1);
		this.removed = true;
	}

	setOriginal() {
		this.orig = JSON.parse(JSON.stringify(this.app));
	}

	async createModule() {
		if (!this.validate()) return;
		let form = this.getForm();
		let p = store_api.createApp(form);
		setLoading('Saving..', p);
		let newApp = await p;
		this.app.id = newApp.id;
		this.app.api_key = newApp.api_key;
		this.uploadAssetFiles(newApp.id);
		this.removed = false;
		this.setOriginal();
	}

	async updateModule() {
		if (!this.validate) return;
		let form = this.getForm();
		let p = store_api.updateApp(form);
		setLoading('Saving..', p);
		await p;
		this.uploadAssetFiles(form.id);
		this.removed = false;
		this.setOriginal();
	}

	validate(): boolean {
		this.error = null;
		if (!this.app.name || !this.app.description || !this.app.version) {
			this.error = 'Name, Description, and Version Required';
			return false;
		}
		return true;
	}

	getForm(): IAppInfo {
		let orig = this.app.images.length - this.files.length;
		let images = [];
		for (let i = 0; i < orig; i++) {
			let url = this.app.images[i];
			let slash = url.lastIndexOf('/');
			images.push(url.slice(slash + 1));
		}

		this.files.forEach(f => {
			images.push(f.name);
		});

		let tile_image = null;
		if (this.tileFile) {
			tile_image = this.tileFile.name;
		} else if (this.app.tile_image) {
			let slash = this.app.tile_image.lastIndexOf('/');
			tile_image = this.app.tile_image.slice(slash + 1);
		}

		let form: IAppInfo = {
			id: this.app.id,
			name: this.app.name,
			version: this.app.version,
			description: this.app.description,
			launch_url: this.app.launch_url,
			is_rtc: this.app.is_rtc,
			is_lti: this.app.is_lti,
			published: this.app.published,
			price: this.app.price,
			long_desc: this.app.long_desc,
			tile_image,
			images,
			tags: this.app.tags,
			api_key: undefined
		};
		return form;
	}

	uploadAssetFiles(id: string) {
		let files = this.files.slice();
		if (this.tileFile) {
			files.push(this.tileFile);
		}
		if (files.length === 0 || this.removed) return;
		store_api.uploadAppAssets(id, files).onload = () => {
			this.files = [];
			this.tileFile = null;
		};
	}

	cancel() {
		router.replace('/dashboard');
	}

	addImage(files: Array<File>, isTile: boolean) {
		if (!files || !files.length) return;
		let file = files[0];
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			() => {
				if (isTile) {
					this.app.tile_image = reader.result as string;
					this.tileFile = file;
				} else {
					this.app.images.push(reader.result as string);
					this.files.push(file);
				}
			},
			false
		);
		reader.readAsDataURL(file);
	}

	async deleteModule() {
		if (confirm('Are you sure you want to delete this app?')) {
			let p = store_api.deleteApp(this.app.id);
			setLoading('Saving..', p);
			await p;
			this.app = null;
			router.replace('/dashboard');
		}
	}
}

const ToggleButton = Component((p: { value: boolean; disabled?: boolean; toggle: () => void }) => {
	function toggle() {
		if (p.disabled) return;
		p.toggle();
		//this.$emit('input', !this.value);
	}

	return () => (
		<div onClick={toggle} class={{ toggle_button: true, 'is-on': p.value, disabled: p.disabled }}>
			<div class="toggle_grabber"></div>
		</div>
	);
});

export const EditApp = Component(() => {
	const l = Vue.reactive(new LocalState());
	l.init();

	if (!state.logged_in) {
		router.replace('/');
	}

	const el = Vue.ref(null);

	Vue.onMounted(() => {
		let div: HTMLElement = el.value;
		let menu = div.querySelector('#tag-menu-container');
		div.onmousedown = e => {
			//@ts-ignore
			if (menu.contains(e.target)) return;
			l.showTagMenu = false;
		};
	});

	return () => (
		<div class="store-page" ref={el}>
			<div id="edit_module" class="page" style={{ width: 800, margin: 'auto', paddingTop: 20 }}>
				<h3 class="">App Details</h3>
				<div class="content">
					<div class="form">
						<div class="row">
							<label>Name</label>
							<input ref={focusRef()} value={l.app.name} />
						</div>
						<div class="row">
							<label>Short Description (max length: 100)</label>
							<input value={l.app.description} maxlength="100" />
						</div>
						<div class="row">
							<label>Long Description</label>
							<textarea value={l.app.long_desc} style="width: 100%; height: 100px; resize: both"></textarea>
							<i style="margin-top: 5px; font-size: 0.8em; display: block">
								this textarea supports
								<a class="link" href="https://www.markdownguide.org/basic-syntax/" target="_blank">
									markdown syntax
								</a>
							</i>
						</div>
						<div class="row" id="tag-menu-container" style="position: relative">
							<label style="display: flex; align-items: center">
								<svg-icon icon="tag" size="1.5em" style="color: var(--accent-600)" />
								&nbsp;Tags
							</label>
							<div class="tagBox" onMousedown={e => (l.showTagMenu = !l.showTagMenu)}>
								{l.activeTags.map(tag => (
									<div class="activeTag" onMousedown={e => l.removeTag(tag)}>
										{tag}
									</div>
								))}
							</div>
							<div id="tag-menu-container" style="position: relative">
								{l.showTagMenu && (
									<div id="tagMenu" style="top: 0.5em">
										{l.tags.map(tag => (
											<div class="tag" onClick={e => l.addTag(tag)}>
												{{ tag }}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
						<div class="columns">
							<div class="row">
								<label>Version</label>
								<input value={l.app.version} />
							</div>
							<div class="row">
								<label>Price</label>
								<input value={l.app.price} />
							</div>
						</div>
						<div class="row">
							<label>Launch URL</label>
							<input value={l.app.launch_url} />
							<i style="margin-top: 5px; font-size: 0.8em; display: block">
								for free hosting see
								<a class="link" href="https://netlify.com/" target="_blank">
									netlify.com
								</a>
							</i>
						</div>
						{l.app.id && (
							<div class="row">
								<label>Private API Key (used for LTI API)</label>
								<input readonly value={l.app.api_key} />
							</div>
						)}
						<div class="row-switches">
							<div class="row">
								<label>Enable LTI</label>
								<ToggleButton value={l.app.is_lti} toggle={() => (l.app.is_lti = !l.app.is_lti)} />
							</div>
							<div class="row">
								<label>Enable RealTime</label>
								<ToggleButton value={l.app.is_rtc} toggle={() => (l.app.is_rtc = !l.app.is_rtc)} />
							</div>
							<div class="row">
								<label>Published</label>
								<ToggleButton value={l.app.published} toggle={() => (l.app.published = !l.app.published)} />
							</div>
						</div>
						{l.error && (
							<div class="row">
								<div title="Error">{l.error}</div>
							</div>
						)}
						<div class="row flex gap20">
							{l.app.id && (
								<button class="button button-danger" onClick={e => l.deleteModule()}>
									Delete
								</button>
							)}
							<span style="flex-grow: 1"></span>
							{l.pendingChanges && (
								<button class="button" onClick={e => l.cancel()} style="margin-right: 8px">
									Cancel
								</button>
							)}
							{!l.pendingChanges && (
								<button class="button" onClick={e => l.cancel()} style="margin-right: 8px">
									Done
								</button>
							)}
							{!l.app.id && (
								<button class="button button-accent" onClick={e => l.createModule()}>
									Submit
								</button>
							)}
							{l.app.id && (
								<button disabled={!l.pendingChanges} class="button button-accent" onClick={e => l.updateModule()}>
									Update
								</button>
							)}
						</div>
					</div>
					<div class="images">
						<div class="row">
							<label>Tile Image (300 x 150 pixels)</label>
							<Upload input={e => l.addImage(e, true)}>
								{() =>
									l.app.tile_image ? (
										<div class="image tile">
											<img src={l.app.tile_image.length > 50 ? l.app.tile_image : store_url + l.app.tile_image} />
										</div>
									) : (
										<div>Select Title Image</div>
									)
								}
							</Upload>
						</div>

						<div class="row">
							<label>Additional Images (640 x 400 pixels)</label>
							{l.app.images.map((img, index) => (
								<div class="image">
									<img src={img.length > 50 ? img : store_url + img} />
									<button class="button_remove_image" onClick={e => l.removeImage(index)}>
										<Icon name="x-circle" size="1.6em" />
									</button>
								</div>
							))}
							<Upload input={e => l.addImage(e, false)} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
