import { Component } from './helper';

export const VTitle = Component((p: { text: string }) => {
	const r = Vue.ref(null);
	Vue.onMounted(() => {
		let el: HTMLElement = r.value;
		let p = el.parentElement;
		p.onmouseover = () => {
			var rect = p.getBoundingClientRect();
			el.style.top = rect.top + 6 + 'px';
			el.style.left = rect.left + rect.width + 10 + 'px';
			el.classList.add('active');
		};
		p.onmouseout = () => {
			el.classList.remove('active');
		};
	});

	return () => (
		<div ref={r} class="hover-title">
			{p.text}
		</div>
	);
});
