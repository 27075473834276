import { Component } from '../helpers/helper';
import { router } from '../router';
import { state } from '../state';
import { store_api } from '../storeAPI';

class AccountState {
	user: ILocalUser = null;
	edit: boolean = false;
	password: string = null;

	constructor() {
		this.user = JSON.parse(JSON.stringify(state.luser));
	}

	cancel() {
		this.user = JSON.parse(JSON.stringify(state.luser));
		this.edit = false;
	}

	async save() {
		let r = await store_api.updateAccount({
			is_dev: this.user.is_dev,
			email: this.user.email,
			name: this.user.name,
			secret: this.user.secret,
			newPassword: this.password
		});
		state.setUser(r);
		this.edit = false;
	}
}

export const AccountPage = Component(() => {
	const l = Vue.reactive(new AccountState());

	if (!state.luser.email) {
		router.replace('/browse');
		return;
	}

	return () => (
		<div class="store-page">
			<div style="width: 500px; margin: auto" class="page page-content">
				<h1>Account</h1>
				<div style="max-width: 500px; margin-top: 1rem">
					<div class="form-row">
						<label>Email</label>
						<input value={l.user.email} onChange={e => (l.user.email = e.target.value)} disabled={!l.edit} />
					</div>

					<div class="form-row">
						<label>Name</label>
						<input value={l.user.name} onChange={e => (l.user.name = e.target.value)} disabled={!l.edit} />
					</div>

					<div class="form-row">
						<label>Developer</label>
						<toggle-button v-model="user.is_dev" disabled="!edit" />
					</div>

					<div class="form-row" v-if="edit">
						<label>New Password (optional)</label>
						<input
							name="password"
							value={l.user.password}
							onChange={e => (l.user.password = e.target.value)}
							type="password"
						/>
					</div>

					{l.edit && (
						<div class="form-row">
							<label>Secret Code 😉</label>
							<input name="secret" value={l.user.secret} onChange={e => (l.user.secret = e.target.value)} />
						</div>
					)}
				</div>

				<div class="form-buttons">
					{l.edit ? (
						<div>
							<button class="button button-accent" onClick={e => l.save()}>
								Save Changes
							</button>
							<button class="button" onClick={e => l.cancel()} style="margin-left: 0.5rem">
								Cancel
							</button>
						</div>
					) : (
						<div>
							<button class="button button-accent" onClick={e => (l.edit = true)}>
								Edit
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
});
