import { css } from 'goober';
import { Component, removeFromArray } from '../helpers/helper';

const c_toast = css`
	background: royalblue;
	font-size: 16px;
	padding: 12px;
	border-radius: 5px;
	color: white;
	animation: fadeIn 0.3s;

	&.error {
		background: darkred;
	}
`;

const c_toast_list = css`
	z-index: 1000;
	position: fixed;
	bottom: 20px;
	right: 20px;
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

let toast_id = 0;

interface IToast {
	message: string;
	error: boolean;
	id: number;
}

const lstate = Vue.reactive({
	toasts: [] as IToast[]
});

const Toast = Component((p: { t: IToast }) => {
	setTimeout(() => {
		removeFromArray(p.t, lstate.toasts);
	}, 8000);

	const classes = {};
	classes[c_toast] = true;
	classes['error'] = p.t.error;

	return () => <div class={classes}>{p.t.message}</div>;
});

export const ToastList = Component(() => {
	return () => (
		<div class={c_toast_list}>
			{lstate.toasts.map(t => (
				<Toast t={t} key={t.id} />
			))}
		</div>
	);
});

export function openToast(message: string, error: boolean = false) {
	lstate.toasts.push({ message, error, id: toast_id++ });
}
