import { Component } from '../helpers/helper';
import { state, store_url } from '../state';

const AppTile = Component((p: { app: IAppInfo }) => {
	return () => (
		<div
			class={{ appImage: true }}
			onClick={e => {
				state.appInfo = p.app;
				state.stopBrowsingApps();
				if (state.hasChannel) {
					api.activateApp(p.app.id, true);
				}
			}}
		>
			<img src={store_url + p.app.tile_image} />
			<h1>{p.app.name}</h1>
			<div class="desc">{p.app.description}</div>
		</div>
	);
});

export const Store = Component(() => {
	return () => (
		<div class="dlg-wrapper" onClick={e => state.stopBrowsingApps()}>
			<div id="store" onClick={e => e.stopPropagation()}>
				{state.appList.map(a => (
					<AppTile app={a}></AppTile>
				))}
			</div>
		</div>
	);
});
