import { Component } from '../helpers/helper';
import { TooltipC, tooltipRef } from '../helpers/tooltip';
import { VTitle } from '../helpers/vtitle';
import { state } from '../state';
import { Icon } from './icon';

export const ThinSidebar = Component(() => {
	function toggleStore() {
		if (state.browsingApps) {
			state.stopBrowsingApps();
		} else {
			state.browseApps();
		}
	}

	function onAppClick() {
		state.closeApp();
	}

	return () => (
		<div id="thin_sidebar">
			{state.audioEnabled && (
				<button onClick={e => state.enableMic(state.micMuted)} class={{ live: !state.micMuted }}>
					<VTitle text={state.micMuted ? 'Enable Mic' : 'Mute Mic'} />
					<TooltipC text="Unmute your mic here" />
					{!state.micMuted && <Icon name="microphone" size="24px" />}
					{state.micMuted && <Icon name="microphone-slash" size="24px" />}
				</button>
			)}
			{state.audioEnabled && (
				<button onClick={e => state.shareScreen()} class={{ live: state.sharingScreen }}>
					<VTitle text="Share Screen" />
					<Icon name="tv" size="24px" />
				</button>
			)}
			{state.audioEnabled && (
				<button onClick={e => state.enableCam()} class={{ live: state.sharingCam }}>
					<VTitle text="Webcam" />
					<Icon name="video" size="24px" />
				</button>
			)}
			<button
				class={{ 'user-button': true, live: state.expanded }}
				style="position: relative; font-size:20px"
				onClick={e => state.toggleSidebar()}
			>
				<VTitle text="Toggle Sidebar" />
				<Icon name="users" style="position: absolute; left: 2px; font-size:24px" />
				<span style="position: absolute; right: 2px">{state.users.length}</span>
			</button>

			<button onClick={toggleStore} class={{ live: state.browsingApps }}>
				<VTitle text="Start App" />
				<TooltipC text="Start an app here" />
				<Icon name="play" size="24px" />
			</button>
			{state.appLoaded && (
				<button onClick={onAppClick} class={{ live: state.appLoaded }}>
					<VTitle text={'Stop ' + state.appName} />
					<Icon name="stop" size="24px" />
				</button>
			)}
			<button onClick={e => state.hangup()}>
				<VTitle text="Leave Channel" />
				<Icon name="phone-slash-solid" size="24px" />
			</button>
			<div class="grow"></div>
			<button onClick={e => (state.showShareDlg = true)} class={{ live: state.showShareDlg }}>
				<VTitle text="Share Link" />
				<Icon name="link-solid" size="24px" />
			</button>
			<button onClick={e => (state.showSettingsDlg = true)}>
				<VTitle text="Settings" />
				<Icon name="cog" size="24px" />
			</button>
			<button onClick={e => (state.showSupportDlg = true)}>
				<VTitle text="help & feedback" />
				<Icon name="question" size="24px" />
			</button>
		</div>
	);
});
