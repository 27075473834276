fetch('/icons.svg').then(r => {
	r.text().then(r => {
		var div = document.createElement('div');
		div.innerHTML = r;
		div.style.display = 'none';
		document.body.append(div);
	});
});

export const Icon = (p: { name: string; style?: string; class?: string; size?: string }) => {
	const svgStyle = {
		fontSize: p.size
	};

	let c = 'icon';
	if (p.class) {
		c += ' ' + p.class;
	}
	return (
		<span class={c} style={p.style || svgStyle}>
			<svg>
				<use href={'#' + p.name}></use>
			</svg>
		</span>
	);
};
