import { RouterView } from 'vue-router';
import { captions } from './closedCaptions';
import { linkify } from './helpers/helper';
import { metrics } from './metrics';
import { router } from './router';
import { default_channel_id, state, store_url } from './state';
import { initTheme } from './themes';
import { local_media_promise, webRTC } from './webRTC';
import { ToastList } from './components/toast';
import { Navbar } from './components/navbar';
import { Loading } from './components/loading';

initTheme();

const app = Vue.createApp({
	setup() {
		return () =>
			state.hasChannel ? (
				<RouterView />
			) : (
				<div id="app">
					<Navbar />
					<RouterView />
					<ToastList />
					<Loading />
				</div>
			);
	}
});

app.use(router);
app.mount('#root_app');

//@ts-ignore
state.apiInitPromise = api.init({
	platformId: 'gatheract',
	events: {
		apiMessage: onApiMessage
	}
});

window.onbeforeunload = function (e) {};

app.config.errorHandler = function (err, vm, info) {
	console.error(err);
};

function updateChannelUrl(channel: string) {
	if (channel === default_channel_id) return;
	const params = new URLSearchParams(location.search);
	if (location.pathname.match('^/client.*')) {
		channel = 'client/' + channel;
	}
	if (params.toString().length > 0) {
		window.history.replaceState({}, '', `${location.origin}/${channel}?${params.toString()}`);
	} else {
		window.history.replaceState({}, '', `${location.origin}/${channel}`);
	}
}

async function onApiMessage(msg: IApiMessage) {
	if (msg.type === 'connected') {
		state.connected = true;
	} else if (msg.type === 'socket_closed') {
		state.connected = false;
		state.hangup();
	} else if (msg.type === 'join_channel_response') {
		metrics.user = msg.user.name;
		metrics.channels.push(msg.channel);
		state.channel_id = msg.channel;
		state.user = msg.user;
		updateChannelUrl(msg.channel);
		state.audioEnabled = msg.audioEnabled;
		if (state.audioEnabled) {
			//local_media_promise = webRTC.setup_local_media();
		}

		if (state.app_id) {
			api.activateApp(state.app_id, true);
			state.app_id = null;
		}
		await webRTC.setAudioOptions();
	} else if (msg.type === 'channel_info') {
		if (msg.newUser) {
			state.messages.push({
				from: '[Channel]',
				text: `${msg.newUser.name} joined the channel.`,
				url: null
			});
			api.send({ type: 'muted', muted: state.micMuted, user: state.user });
		}
		if (msg.leftUser) {
			state.messages.push({
				from: '[Channel]',
				text: `${msg.leftUser.name} left the channel.`,
				url: null
			});
		}

		state.updateUsers(msg.users);
		state.isHost = msg.host.id === state.user.id;
	} else if (msg.type == 'post_message') {
		state.messages.push({
			from: msg.from,
			text: msg.message,
			url: linkify(msg.message)
		});
		Vue.nextTick(() => {
			let chat = document.querySelector('.chat_messages');
			chat.scrollTop = chat.scrollHeight;
		});
	} else if (msg.type == 'activate_app') {
		state.appName = msg.name;
		state.appUrl = msg.url;
		state.browsingApps = false;
		state.appImageUrl = store_url + msg.image;
		state.setMainVideo(null);
		Vue.nextTick(() => {
			let frame: HTMLElement = document.querySelector('#app-frame');
			frame.focus();
		});
		metrics.startApp(msg.name);
	} else if (msg.type == 'webrtc_init_connection') {
		await local_media_promise;
		webRTC.startConnection(msg.peer_id);
	} else if (msg.type == 'webrtc_offer') {
		await local_media_promise;
		webRTC.onWebRtcMessage(msg.peer_id, msg.offer, null);
	} else if (msg.type == 'webrtc_answer') {
		await local_media_promise;
		webRTC.onWebRtcMessage(msg.peer_id, msg.answer, null);
	} else if (msg.type == 'webrtc_drop_connection') {
		await local_media_promise;
		webRTC.removePeer(msg.peer_id);
	} else if (msg.type == 'webrtc_ice_candidate') {
		await local_media_promise;
		webRTC.onWebRtcMessage(msg.peer_id, null, msg.ice_candidate);
	} else if (msg.type == 'captions_enable') {
		if (msg.enable) {
			state.captions_enabled = true;
			captions.init();
		} else {
			state.captions_enabled = false;
			captions.stopForever();
		}
	} else if (msg.type == 'caption') {
		let n = '';
		state.users.forEach(u => {
			if (u.id == msg.from) {
				n = u.name;
			}
		});
		let packet: ICaptionPart = {
			from: msg.from,
			text: msg.message,
			time: Date.now(),
			name: n,
			finished: msg.finished
		};
		captions.addMessage(packet);
	} else if (msg.type == 'muted') {
		state.updateUserMutedState(msg.user, msg.muted);
	} else if (msg.type == 'close_app') {
		state.closeApp(false);
	}
}
