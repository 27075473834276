import { Home } from '../dialogs/home';
import { SettingsDlg } from '../dialogs/settingsDlg';
import { ShareDlg } from '../dialogs/shareDlg';
import { SupportDlg } from '../dialogs/supportDlg';
import { Component } from '../helpers/helper';
import { state } from '../state';
import { Channel } from './channel';
import { Store } from './store';

export const ClientPage = Component(() => {
	return () =>
		state.hasChannel ? (
			<div id="app" class="client">
				<Channel></Channel>

				{state.showShareDlg && <ShareDlg />}

				{state.showSupportDlg && <SupportDlg />}

				{state.showSettingsDlg && <SettingsDlg />}

				{state.browsingApps && <Store />}
			</div>
		) : (
			<Home />
		);
});
