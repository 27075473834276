import { RouterLink } from 'vue-router';
import { Component } from '../helpers/helper';
import { fluffRoutes } from './fluff';

export const AboutPage = Component(() => {
	return () => (
		<div class="store-page">
			<div id="fluff" style="flex-grow: 1">
				<div class="main">
					<h1>Collaborative Applications</h1>
					<p class="message">
						Collaborative applications are the next step in the evolution of virtual communication and will redefine the
						way we interact with each other. For many, video-chat and screen sharing alone are not a sufficient
						replacement for resources they have during in-person meetings. GatherAct is a marketplace for collaborative
						apps that can be integrated into online meetings, virtual classrooms, and Learning Management Systems.
					</p>

					<h1>What Can GatherAct Do</h1>
					<p class="message">
						GatherAct offers free easy to use meeting rooms and is the next big leap in online collaboration. Let's
						compare GatherAct vs. Google Meet and Zoom's Free Meeting Plans.
					</p>
					<table>
						<thead>
							<th></th>
							<th>GatherAct</th>
							<th>Google Meet</th>
							<th>Zoom</th>
						</thead>
						<tr>
							<td>
								<b>Secure Peer-To-Peer Connections</b>
							</td>
							<td class="good">Yes</td>
							<td class="good">Yes</td>
							<td class="bad">No</td>
						</tr>
						<tr>
							<td>
								<b>Video Quality</b>
							</td>
							<td class="good">Up to 4k, limited only by your webcam resolution and internet connection</td>
							<td class="bad">Limited to 720p</td>
							<td class="bad">Limited to 720p</td>
						</tr>
						<tr>
							<td>
								<b>Screen Sharing</b>
							</td>
							<td class="good">Everyone can share their screen at the same time!</td>
							<td class="bad">Only one person</td>
							<td class="bad">Only one person</td>
						</tr>
						<tr>
							<td>
								<b>Collaborative Apps</b>
							</td>
							<td class="good">A marketplace with apps made by developers all around the world.</td>
							<td class="bad">Whiteboard only</td>
							<td class="bad">Whiteboard only</td>
						</tr>
						<tr>
							<td>
								<b>In Meeting Chat</b>
							</td>
							<td class="good">Yes</td>
							<td class="good">Yes</td>
							<td class="good">Yes</td>
						</tr>
						<tr>
							<td>
								<b>Time Limit</b>
							</td>
							<td class="good">None, go for as long as you want.</td>
							<td class="bad">60 Minutes</td>
							<td class="bad">40 Minutes</td>
						</tr>
						<tr>
							<td>
								<b>Collecting Personal Information</b>
							</td>
							<td class="good">Anyone can create a meeting room, now, for free. No login required.</td>
							<td class="bad">At least the host needs a Google account.</td>
							<td class="bad">At least the host needs a Zoom account.</td>
						</tr>
						<tr>
							<td>
								<b>Recording</b>
							</td>
							<td>No, GatherAct doesn't store your personal information during meetings.</td>
							<td>Only for paid plans</td>
							<td>Only for paid plans</td>
						</tr>
						<tr>
							<td>
								<b>Number of Participants</b>
							</td>
							<td>approx. 20 Participants, limited by internet connection</td>
							<td>100 Participants</td>
							<td>100 Participants</td>
						</tr>
					</table>

					<h1>For Developers</h1>
					<p class="message">
						Any web app can be published on GatherAct, just create an account and tell us the URL to launch.
						<br />
						GatherAct also provides a simple API for developers to enable real-time collaboration on new or exiting web
						apps.
						<br />
						<RouterLink to="create">{() => 'Documentation'}</RouterLink>
					</p>

					<h2>More Resources</h2>
					<div class="bullets" style="font-size: 18px;">
						<ul>
							{/* <li>
								<router-link to="/education">The Future of Online Learning Through Collaboration</router-link>
							</li> */}
							{fluffRoutes.map(item => (
								<li>
									<RouterLink to={item.path}>{() => item.name}</RouterLink>
								</li>
							))}
						</ul>
					</div>

					<RouterLink to="/terms_of_service">{() => 'Terms of Service'}</RouterLink>
					<RouterLink to="/privacy_policy">{() => 'Privacy Policy'}</RouterLink>
					<br />
					<br />
				</div>
			</div>
		</div>
	);
});
