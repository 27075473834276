import { Icon } from '../components/icon';
import { Component } from '../helpers/helper';
import { state } from '../state';

export const ShareDlg = Component(() => {
	const l = Vue.reactive({
		message: null,
		link: document.location.href
	});

	function close() {
		state.showShareDlg = false;
	}

	function copyShareLink() {
		navigator.clipboard.writeText(l.link);
		let el: HTMLInputElement = document.querySelector('#share-dlg .link');
		el.classList.add('highlight');
		setTimeout(() => {
			el.classList.remove('highlight');
		}, 500);
	}

	return () => (
		<div class="dlg-wrapper" onClick={close}>
			<div id="share-dlg" class="dlg" onClick={e => e.stopPropagation()}>
				<button class="bt-close" onClick={close}>
					<Icon name="times-solid" />
				</button>
				<div class="header">Share your meeting with this link</div>
				<div class="sub-header">You can rejoin this room any time.</div>
				<div class="link">{l.link}</div>
				<div style="text-align: center; margin-top: 1em">
					<button class="button" style="display: inline" onClick={copyShareLink}>
						Copy Link
					</button>
				</div>
			</div>
		</div>
	);
});
