import { RouterLink } from 'vue-router';
import { Component } from '../helpers/helper';
import { router } from '../router';
import { state, store_url } from '../state';
import { store_api } from '../storeAPI';
import { Module } from './browsePage';
import { Icon } from '../components/icon';

class LocalState {
	devApps: IAppInfo[] = [];
	recentApps: IAppInfo[] = [];
	vclient: IVClient = null;

	get isEmpty(): boolean {
		return !this.devApps.length && !this.recentApps.length && !this.vclient;
	}

	async init() {
		this.refreshList();
		//this.recentApps = await store_api.myApps();
		//this.vclient = await store_api.getDevVClient();
	}

	async refreshList() {
		this.devApps = await store_api.devApps();
	}

	selectModule(id: string) {
		router.push(`/edit-app/${id}`);
	}

	async enableDev() {
		let r = await store_api.updateAccount({ is_dev: true });
		state.setUser(r);
	}

	registerVirtualClient() {
		router.push(`/virtual-client/new`);
	}

	editVClient() {
		router.push(`/virtual-client/${this.vclient.id}`);
	}

	addSuggestion() {
		//SuggestionDlg.open();
	}
}

export const Dashboard = Component(() => {
	const l = Vue.reactive(new LocalState());
	l.init();

	return () => (
		<div id="dashboard" class="store-page">
			{state.luser.is_dev && (
				<div style="margin-top: 1em">
					<div style={{ textAlign: 'center' }}>
						<h3>Your Apps</h3>
					</div>
					<div class="flex center gap20 wrap" style={{ padding: 100 }}>
						{/* <Module class="dark" app={{ name: 'New App', id: 'new' }} newApp="true" devApp="true"></small-app> */}
						{l.devApps.map(app => (
							// <Module key={app.id} app={app}></Module>
							<div>
								<div
									class="flex center jcenter"
									onClick={e => router.push('/edit-app/' + app.id)}
									style={{
										cursor: 'pointer',
										width: 200,
										height: 100,
										background: 'aqua',
										fontSize: 40,
										borderRadius: '10px',
										overflow: 'hidden'
									}}
								>
									{app.tile_image ? (
										<img style={{ width: 200, height: 100 }} src={store_url + app.tile_image} />
									) : (
										<Icon name="app-cube" size="6rem" style="color: var(--currentColor)" class="cube w-6 h-6" />
									)}
								</div>
								<RouterLink to={'/edit-app/' + app.id}>{() => app.name}</RouterLink>
								{app.published ? <div>Published</div> : <div style={{ color: '#ccc' }}>Unpublished</div>}
							</div>
						))}
					</div>
				</div>
			)}
			{!state.luser.is_dev && (
				<div v-else style="margin-top: 2rem">
					Looking to create apps or integrate GatherAct into your virtual platform? click
					<a onClick={e => l.enableDev()}>HERE</a>
					to enable dev mode
				</div>
			)}
		</div>
	);
});
