import { Component } from '../helpers/helper';
import { router } from '../router';
import { state, store_url } from '../state';
import { store_api } from '../storeAPI';

export const AppPage = Component(() => {
	const l = Vue.reactive({
		app: null as IAppInfo,
		share_url: null as string
	});

	Vue.onMounted(async () => {
		let id = router.currentRoute.value.params.id as string;
		l.app = await store_api.getApp(id);
		//TODO: get unique channel ID or even platform (i.e. zipcall)
		//l.share_url = await api.getAppLaunchUrl(id);
		//l.app = await api.getApp(id);
		//@ts-ignore
		//var converter = new showdown.Converter();
		//l.app.long_desc = converter.makeHtml(l.app.long_desc);
		//updateMeta(this.app);

		/*Vue.nextTick(() => {
			//@ts-ignore
			gapi.sharetoclassroom.render('google_class_share', {
				url: l.share_url,
				size: 50,
				theme: 'classic',
				title: 'Share to Google Classroom'
			});
		});*/
	});

	function play() {
		if (state.hasChannel) {
			api.activateApp(l.app.id, true);
		} else {
			state.appInfo = l.app;
			router.push('/');
		}
	}

	// update meta description with app description
	function updateMeta(app: IAppInfo) {
		let metaTags = [
			{
				name: 'description',
				content: app.description
			},
			{
				property: 'og:description',
				content: app.description
			},
			{
				property: 'og:image',
				content: 'https://gatheract.com' + app.tile_image
			},
			{
				property: 'twitter:card',
				content: 'https://gatheract.com' + app.tile_image
			}
		];

		Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

		//add the og:title tag
		const tag = document.createElement('meta');
		tag.setAttribute('og:title', document.title);
		// We use this to track which meta tags we create, so we don't interfere with other ones.
		tag.setAttribute('data-vue-router-controlled', '');
		// Add the meta tags to the document head.
		document.head.appendChild(tag);

		//add the twitter:image:alt tag
		const tag2 = document.createElement('meta');
		tag2.setAttribute('twitter:image:alt', document.title);
		// We use this to track which meta tags we create, so we don't interfere with other ones.
		tag2.setAttribute('data-vue-router-controlled', '');
		// Add the meta tags to the document head.
		document.head.appendChild(tag2);

		// Turn the meta tag definitions into actual elements in the head.
		metaTags
			.map(tagDef => {
				const tag = document.createElement('meta');

				Object.keys(tagDef).forEach(key => {
					tag.setAttribute(key, tagDef[key]);
				});

				// We use this to track which meta tags we create, so we don't interfere with other ones.
				tag.setAttribute('data-vue-router-controlled', '');

				return tag;
			})
			// Add the meta tags to the document head.
			.forEach(tag => document.head.appendChild(tag));
	}

	return () => (
		<div id="app_info" class="store-page">
			{l.app && (
				<div class="app_content">
					<div class="app_info">
						<h1 class="name">{l.app.name}</h1>
						<p class="description">{l.app.description}</p>
						{l.app.long_desc && (
							<div class="app_section">
								<div class="app_section_header">
									<svg-icon icon="badge-check" size="24px" />
									<h3>Features</h3>
								</div>
								<div>{l.app.long_desc}</div>
							</div>
						)}
						<div class="app_section">
							{l.app.images.length && (
								<div class="app_section_header">
									<svg-icon icon="camera" size="24px" />
									<h3>Screenshots</h3>
								</div>
							)}
							<image-slide images={l.app.images} />
						</div>
					</div>
					<div class="app_meta">
						<img class="app_tile" src={store_url + l.app.tile_image} alt="Tile Image" />

						<div class="buttons">
							<button class="button button-accent button-play" onClick={play}>
								Start
							</button>
						</div>

						<div class="row">
							<div class="icon">
								<svg-icon icon="user" size="20px" />
							</div>
							<div class="data">
								<p class="label">Developer:</p>
								<p class="value">GatherAct</p>
							</div>
						</div>

						<div class="row">
							<div class="icon">
								<svg-icon icon="credit-card" size="20px" />
							</div>
							<div class="data">
								<p class="label">Cost:</p>
								<p class="value">{l.app.price}</p>
							</div>
						</div>

						<div class="row">
							<div class="icon">
								<svg-icon icon="code" size="20px" />
							</div>
							<div class="data">
								<p class="label">Version:</p>
								<p class="value">1.0</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
});
