import { DefineSetupFnComponent, defineComponent } from "vue";

let dtf = new Intl.DateTimeFormat('en', {
	year: '2-digit',
	month: 'short',
	day: '2-digit',
	hour: 'numeric',
	minute: '2-digit'
});

export function formatDate(val) {
	let d = new Date(val);
	return dtf.format(d);
}

export function removeFromArray(item: any, array: any[]) {
	for (let i = array.length - 1; i >= 0; i--) {
		if (array[i] === item) {
			array.splice(i, 1);
		}
	}
}

export function getQueryVariable(variable): string {
	var query = window.location.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) == variable) {
			return decodeURIComponent(pair[1]);
		}
	}
}

export function onIframeLoad() {
	let iframes = document.querySelectorAll('iframe');
	iframes.forEach(e => {
		e.style.visibility = 'hidden';
		window.requestAnimationFrame(() => {
			e.style.visibility = 'visible';
		});
	});
}

export function Component<T>(setup: (p: T, any?: any) => any):DefineSetupFnComponent<T> {
	let r:any = defineComponent({
		setup: (p, c) => {
			return setup(<T>c.attrs, c);
		}
	});

	return r;
}

var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
export function linkify(text: string): string {
	let found = null;
	text.replace(urlRegex, function (url) {
		found = url;
		return url;
	});
	return found;
}

export function createCookie(name: string, value: string) {
	document.cookie = `${name}=${value}`;
}

export function getCookie(name: string): string {
	let cookie = document.cookie.split('; ').find(row => row.startsWith(name));
	if (cookie) {
		let result = cookie.split('=')[1];
		if (result) {
			result = decodeURIComponent(result);
		}
		return result;
	}
}

export function eraseCookie(name: string) {
	document.cookie = name + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getRandomInt(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}
