import { css } from 'goober';
import { dlg } from '../helpers/dlg';
import { Component, formatDate } from '../helpers/helper';
import { service } from '../service';
import { state } from '../state';

const c_filters = css`
	display: flex;
	gap: 20px;
	align-items: center;
	margin-bottom: 10px;
	input,
	button,
	select {
		height: auto;
		width: unset;
		font-size: 12px;
		padding: 6px;
	}
`;

interface IUserGroup {
	user: string;
	list: IMetric[];
}

const GroupByUser = Component((p: { groups: IUserGroup[] }) => {
	const el = Vue.ref(null);
	return () => (
		<div class="dlg-wrapper" onClick={e => dlg.close(el)} ref={el}>
			<div class="dlg" style={{ background: 'white', maxHeight: '100%', overflow: 'auto' }}>
				{p.groups.map(g => (
					<a
						onClick={e => {
							dlg.close(el, g);
						}}
					>
						<div style={{ width: 200, overflow: 'hidden' }}>{g.user}</div>
						{g.list.length}
					</a>
				))}
			</div>
		</div>
	);
});

export const Metrics = Component(() => {
	const l = Vue.reactive({ metrics: [] as IMetric[], days: 7, filter: [] as IMetric[] });

	function getData() {
		service.getMetrics(l.days).then(r => {
			l.metrics = r;
			l.filter = l.metrics;
		});
	}

	function showInfo(m: IMetric) {
		if (m.info) alert(JSON.stringify(m.info));
	}

	async function groupByUsers() {
		let user_map = new Map<string, IUserGroup>();

		for (let m of l.metrics) {
			if (!m.user) continue;
			let g = user_map.get(m.user);
			if (!g) {
				g = { user: m.user, list: [] };
				user_map.set(m.user, g);
			}
			g.list.push(m);
		}

		let groups = [...user_map.values()];
		groups.sort((a, b) => b.list.length - a.list.length);
		const g: IUserGroup = await dlg.open(GroupByUser, { groups });
		if (g) l.filter = g.list;
	}

	async function groupByDate() {
		let user_map = new Map<string, IUserGroup>();

		for (let m of l.metrics) {
			if (!m.user) continue;
			let d = new Date(m.date).toDateString();
			let g = user_map.get(d);
			if (!g) {
				g = { user: d, list: [] };
				user_map.set(d, g);
			}
			g.list.push(m);
		}

		let groups = [...user_map.values()];
		const g: IUserGroup = await dlg.open(GroupByUser, { groups });
		if (g) l.filter = g.list;
	}

	async function groupByChannel() {
		let user_map = new Map<string, IUserGroup>();

		for (let m of l.metrics) {
			if (!m.info.channels) continue;
			for (let c of m.info?.channels) {
				let g = user_map.get(c);
				if (!g) {
					g = { user: c, list: [] };
					user_map.set(c, g);
				}
				g.list.push(m);
			}
		}

		let groups = [...user_map.values()];
		groups.sort((a, b) => b.list.length - a.list.length);
		const g: IUserGroup = await dlg.open(GroupByUser, { groups });
		if (g) l.filter = g.list;
	}

	getData();

	return () => (
		<div class="store-page" id="metrics" onClick={e => e.stopPropagation()}>
			<div class={c_filters}>
				<select
					style={{ width: 'unset' }}
					value={l.days}
					onChange={e => {
						l.days = parseInt(e.target.value);
						getData();
					}}
				>
					<option value={7}>Last 7 days</option>
					<option value={30}>Last 30 days</option>
					<option value={90}>Last 90 days</option>
					<option value={365}>Last 365 days</option>
				</select>

				<button onClick={e => (l.filter = l.metrics)}>Reset</button>
				<button onClick={groupByUsers}>Group By User</button>
				<button onClick={groupByDate}>Group By Date</button>
				<button onClick={groupByChannel}>Group By Channel</button>
			</div>
			<table>
				<thead>
					<tr>
						<th>date</th>
						<th>location</th>
						<th>user</th>
						<th>ad</th>
						<th>geo</th>
						<th>seconds</th>
						<th>channels</th>
						<th>referer</th>
						<th>info</th>
					</tr>
				</thead>
				<tbody>
					{l.filter.map(m => (
						<tr key={m.id}>
							<td>{formatDate(m.date)}</td>
							<td style={{ maxWidth: 300, wordBreak: 'break-all' }}>{m.location}</td>
							<td>{m.user?.slice(0, 20)}</td>
							<td>{m.ad}</td>
							<td>
								{m.geo && (
									<div>
										{m.geo.city}, {m.geo.region}
									</div>
								)}
							</td>
							<td>{m.seconds}</td>
							<td>
								{[...new Set(m.info?.channels)].map(c => (
									<div>{c.slice(0, 20)}</div>
								))}
							</td>
							<td>{m.referer}</td>
							<td>
								<a onClick={e => showInfo(m)}>View Info</a>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
});
