import { Icon } from '../components/icon';
import { Component } from '../helpers/helper';
import { state } from '../state';
import { webRTC } from '../webRTC';

export const SettingsDlg = Component(() => {
	Vue.onMounted(() => {
		if (webRTC.audioInputDeviceId) {
			webRTC.setAudioOptions();
		}
	});

	function close() {
		state.showSettingsDlg = false;
	}

	function setInput(e) {
		webRTC.setAudioInputDevice(e.target.value);
	}

	function setOutput(e) {
		webRTC.setAudioOutputDevice(e.target.value);
	}

	function setVideo(e) {
		webRTC.setVideoInputDevice(e.target.value);
	}

	return () => (
		<div class="dlg-wrapper" onClick={close}>
			<div id="settings-dlg" class="dlg" onClick={e => e.stopPropagation()}>
				<button class="bt-close" onClick={close}>
					<Icon name="times-solid" />
				</button>
				<div class="header">Audio Settings</div>
				{!webRTC.audioInputDeviceId && <div class="sub-header">Your microphone is not enabled</div>}
				<div>
					<label for="microphone">Microphone:</label>
					<select onChange={setInput} value={webRTC.audioInputDeviceId}>
						{webRTC.audioInputDeviceOptions.length == 0 && <option value="null">No audio input devices found</option>}
						{webRTC.audioInputDeviceOptions.map(o => (
							<option value={o.value}>{o.name}</option>
						))}
					</select>
				</div>

				<div>
					<label for="audioOutput">Speaker:</label>
					<select onChange={setOutput} value={webRTC.audioOutputDeviceId}>
						{webRTC.audioOutputDeviceOptions.length == 0 && <option value="null">No audio output devices found</option>}
						{webRTC.audioOutputDeviceOptions.map(o => (
							<option value={o.value}>{o.name}</option>
						))}
					</select>
				</div>

				<div>
					<label for="videoInput">Camera:</label>
					<select onChange={setVideo} value={webRTC.videoInputDeviceId}>
						{webRTC.videoInputDeviceOptions.length == 0 && <option value="null">No cameras found</option>}
						{webRTC.videoInputDeviceOptions.map(o => (
							<option value={o.value}>{o.name}</option>
						))}
					</select>
				</div>
			</div>
		</div>
	);
});
