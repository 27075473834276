interface IConfig {
	spinner?: boolean;
	showError?: boolean;

	params?: any;
	method?: any;
	cache?: any;
	headers?: any;
	user?: ILocalUser;
}

class HTTP {
	get(url: string, config?: IConfig) {
		let _config: RequestInit = {
			method: 'GET',
			cache: 'no-cache',
			headers: {
				'Content-Type': 'application/json',
				auth_token: config?.user && config.user.session_id
			}
		};

		if (config) Object.assign(_config, config);
		return this._fetch(url, _config);
	}

	post(url: string, data: any, config?: IConfig) {
		let _config: RequestInit = {
			method: 'POST',
			cache: 'no-cache',
			headers: {
				'Content-Type': 'application/json',
				auth_token: config?.user && config.user.session_id
			},
			body: JSON.stringify(data)
		};
		if (config) Object.assign(_config, config);
		return this._fetch(url, _config);
	}

	put(url: string, data: any) {
		let config: RequestInit = {
			method: 'POST',
			cache: 'no-cache',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};
		return this._fetch(url, config);
	}

	del(url: string, config?: IConfig) {
		let _config: RequestInit = {
			method: 'DELETE',
			cache: 'no-cache',
			headers: {
				'Content-Type': 'application/json',
				auth_token: config?.user && config.user.session_id
			}
		};

		if (config) Object.assign(_config, config);
		return this._fetch(url, _config);
	}

	_fetch(url: string, config: RequestInit) {
		return fetch(url, config).then(r => {
			const contentType = r.headers.get('content-type');
			let isjson = contentType && contentType.indexOf('application/json') !== -1;

			if (!r.ok) {
				if (r.status === 401) {
					return;
				}
				if (isjson) {
					r.json().then(msg => {
						console.error(msg.message || msg);
					});
				}
				throw new Error(r.statusText);
			} else if (isjson) {
				return r.json();
			} else {
				return r.text();
			}
		});
	}
}

export const http = new HTTP();
