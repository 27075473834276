import { RouterLink } from 'vue-router';
import { Component, createCookie, getCookie } from '../helpers/helper';
import { router } from '../router';
import { state } from '../state';
import { store_api } from '../storeAPI';
import { stat } from 'fs';

const DEFAULT_HOME_PAGE = '/';

class LoginState {
	email = null;
	name = null;
	password = null;
	confirmPassword = null;
	agree = false;
	pw_reset_code = null;
	showPasswordResetCode = false;
	registering = false;
	loginError: string = null;

	async sendResetCode() {
		if (!this.email) {
			this.loginError = 'Please enter your email';
			return;
		}

		let r = await store_api.sendResetCode(this.email);
		if (r.success) {
			this.loginError = null;
			this.showPasswordResetCode = true;
			this.password = null;
		} else if (r.error) {
			this.loginError = r.error;
		}
	}

	async login() {
		if (this.showPasswordResetCode) {
			if (!this.pw_reset_code) {
				alert('You must enter the password reset code sent to your email.');
				return;
			}
			if (!this.confirmPasswords()) return;
		}

		this.loginError = null;
		let r = await store_api.login({
			email: this.email,
			password: this.password,
			pw_reset_code: this.showPasswordResetCode && this.pw_reset_code
		});
		if (r.success) {
			state.setUser(r);
			if (!getCookie('session_id')) {
				createCookie('session_id', r.session_id);
				createCookie('session_email', r.email);
			}
			router.push(DEFAULT_HOME_PAGE);
		} else {
			this.loginError = r.error;
		}
	}

	confirmPasswords(): boolean {
		if (!this.password || this.password !== this.confirmPassword) {
			alert('Both password fields must match');
			return false;
		}
		return true;
	}

	async createAccount() {
		if (!this.name || !this.password || !this.email) {
			alert('Name, Email, and Password Required');
			return;
		}
		if (!this.agree) {
			alert("You must accept GatherAct's Terms of Service and Privacy Policy before you can create an account");
			return;
		}
		if (!this.confirmPasswords()) return;
		let r = await store_api.createdAccount({
			email: this.email,
			password: this.password,
			name: this.name
		});

		if (r.success) {
			state.setUser(r);
			router.push(DEFAULT_HOME_PAGE);
		} else {
			this.loginError = r.error;
		}
	}
}

export const LoginPage = Component(() => {
	const l = Vue.reactive(new LoginState());

	return () => (
		<div class="store-page">
			<form
				id="login"
				onSubmit={e => {
					e.preventDefault();
					l.login();
					return false;
				}}
			>
				{l.showPasswordResetCode && (
					<p>You have been sent an email with a password reset code, Enter that code and your new password below.</p>
				)}
				<h3 class="welcome">Welcome to GatherAct!</h3>
				<h4 class="login">Login</h4>
				<div class="form-row">
					<label>Email</label>
					<input class="input" name="username" value={l.email} onChange={e => (l.email = e.target.value)} />
				</div>
				{l.registering && (
					<div class="form-row">
						<label>Name</label>
						<input class="input" value={l.name} onChange={e => (l.name = e.target.value)} />
					</div>
				)}
				{l.showPasswordResetCode && (
					<div class="form-row">
						<label>Password reset code</label>
						<input value={l.pw_reset_code} onChange={e => (l.pw_reset_code = e.target.value)} />
					</div>
				)}
				<div class="form-row">
					<label>Password</label>
					<input name="password" value={l.password} type="password" onChange={e => (l.password = e.target.value)} />
				</div>
				{(l.registering || l.showPasswordResetCode) && (
					<div class="form-row">
						<label>Confirm Password</label>
						<input value={l.confirmPassword} onChange={e => (l.confirmPassword = e.target.value)} type="password" />
					</div>
				)}
				{l.registering && (
					<div class="form-row">
						<label style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
							<input
								type="checkbox"
								style={{ width: 'unset' }}
								checked={l.agree}
								onChange={e => (l.agree = e.target.value)}
							/>
							<span>
								I agree to GatherAct's
								<br />
								<a href="/terms_of_service" target="_blanks" class="inline">
									Terms of Service
								</a>
								&nbsp;and&nbsp;
								<a href="/privacy_policy" target="_blanks" class="inline">
									Privacy Policy
								</a>{' '}
							</span>
						</label>
					</div>
				)}
				{l.registering && (
					<div class="form-row horizontal gap20">
						<button type="button" onClick={e => l.createAccount()}>
							Create Account
						</button>
						<button type="button" onClick={e => (l.registering = false)}>
							Cancel
						</button>
					</div>
				)}
				{l.showPasswordResetCode && (
					<div class="form-row horizontal gap20">
						<button type="submit">Log in</button>
						<button type="button" onClick={e => (l.showPasswordResetCode = false)}>
							Cancel
						</button>
					</div>
				)}
				{!l.registering && !l.showPasswordResetCode && (
					<div class="form-row">
						<div style="display: flex">
							<button class="button button-accent" type="submit">
								Log in
							</button>
							<button class="button" type="button" onClick={e => l.sendResetCode()} style="margin-left: 12px">
								Forgot Password
							</button>
						</div>
						<div style="margin-top: 2rem">
							<button class="button" type="button" onClick={e => (l.registering = true)}>
								Register New Account
							</button>
						</div>
					</div>
				)}
				{l.loginError && <div class="form-row error">{l.loginError}</div>}
			</form>
		</div>
	);
});
