import { Component } from './helper';

function fadeout(el: HTMLElement, remove: boolean) {
	el.classList.add('fadeout');
	if (remove) {
		setTimeout(() => {
			el.remove();
		}, 1000);
	}
}

class Tooltip {
	el: HTMLElement = null;
	div: HTMLElement = null;
	text: string = null;

	constructor(el: HTMLElement, text: string) {
		this.el = el;
		this.text = text;
	}

	show() {
		let div = (this.div = document.createElement('div'));
		div.classList.add('tooltip');
		div.innerText = this.text;

		setTimeout(() => {
			let rect = this.el.getBoundingClientRect();
			div.style.position = 'fixed';
			div.style.top = `${rect.top + 6}px`;
			div.style.left = `${rect.left + rect.width}px`;
			this.el.appendChild(div);
		}, 2000);

		div.onclick = () => {
			fadeout(div, true);
		};

		setTimeout(() => {
			fadeout(div, true);
		}, 10000);
	}

	remove() {
		if (this.div) this.div.remove();
	}
}

export const tooltipDirective = {
	bind: function (el: HTMLElement, binding) {
		//@ts-ignore
		el.tooltip = new Tooltip(el, binding.value);
	},
	unbind: function (el: HTMLElement) {
		///@ts-ignore
		if (el.tooltip) el.tooltip.remove();
	},
	updated: function () {}
};

export function tooltipRef(e: any, v: string, z?: any) {
	const ref = Vue.ref(null);
	Vue.watch(ref, () => {
		let el = ref.value;
		if (!el) return;
		if (el.custom === undefined) {
			el.tooltip = new Tooltip(el, v);
		}
	});

	return ref;
}

export const TooltipC = Component((p: { text: string }) => {
	const r = Vue.ref(null);
	Vue.watchEffect(() => {
		let el = r.value;
		if (!el || el.tooltip) return;
		let tt = new Tooltip(el.parentElement, p.text);
		el.tooltip = tt;
		tt.show();
	});

	Vue.onUnmounted(() => {});
	return () => <div ref={r}></div>;
});
