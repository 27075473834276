let list: IAppInfo[] = [
	{
		id: '1596382755474636310',
		name: 'Chess',
		version: '1.0',
		description: 'Play chess in real time with two players and unlimited spectators.',
		long_desc: 'Play chess in real time with two players and unlimited spectators.',
		launch_url: null,
		is_rtc: true,
		is_lti: false,
		published: true,
		price: '0.00',
		images: ['/app_assets/1596382755474636310/chess-check.png'],
		tile_image: '/app_assets/1596382755474636310/chess.png',
		api_key: null,
		tags: ['Game', 'Collaborative']
	},
	{
		id: '1596393068809782936',
		name: 'Whiteboard',
		version: '1',
		description: 'Virtual whiteboard for sketching hand-drawn like diagrams',
		long_desc:
			'Draw, write, and diagram on a. collaborative whiteboard.  Use the multiple boards feature to share boards with other users or give each participant their own canvas.',
		launch_url: undefined,
		is_rtc: true,
		is_lti: false,
		published: true,
		price: '0.00',
		images: ['/app_assets/1596393068809782936/whiteboard-logo.png'],
		tile_image: '/app_assets/1596393068809782936/whiteboard-logo.png',
		api_key: '895ca9e9-c8a9-444b-83ba-c7b00665b111',
		tags: ['Collaborative', 'Drawing']
	},
	{
		id: '1596393069030262673',
		name: 'YouTube Share',
		version: '1.0',
		description: 'Watch YouTube Together',
		long_desc:
			'Share a YouTube Video URL and this app automatically synchronizes the video between all the users on the channel. If someone pauses, it pauses for everyone. If someone is buffering, everyone will wait so your videos are all in sync.',
		launch_url: null,
		is_rtc: true,
		is_lti: false,
		published: true,
		price: '0.00',
		images: [
			'/app_assets/1596393069030262673/youtube-video-url.png',
			'/app_assets/1596393069030262673/youtube-settings.png'
		],
		tile_image: '/app_assets/1596393069030262673/youtube.jpg',
		api_key: null,
		tags: ['Collaborative']
	}
];

export const featured_apps = Vue.reactive(list);
