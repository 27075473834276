import { Icon } from '../components/icon';
import { focusRef } from '../helpers/focus';
import { Component, getQueryVariable, getRandomInt, removeFromArray } from '../helpers/helper';
import { VTitle } from '../helpers/vtitle';
import { service } from '../service';
import { state, store_url } from '../state';
import { store_api } from '../storeAPI';
import { router } from '../router';

interface IChannelUser {
	name: string;
}

class LState {
	channelId: string = null;
	loginError: string = null;
	successMsg: string = null;
	newChannel = false;
	baseUrl: string = null;
	user: IChannelUser = { name: '' };

	setChannelIdFromUrl() {
		let parts = document.location.pathname.split('/').reverse();
		removeFromArray('', parts);
		if (parts[0] && parts[0] !== 'client') {
			this.channelId = parts[0];
		}
	}

	async init() {
		let user: IChannelUser = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.user.name = user.name || '';
			state.firstTimeUser = false;
		}

		if (state.app_id) {
			state.appInfo = await store_api.getApp(state.app_id);
		}

		this.baseUrl = document.location.origin;
		if (document.location.pathname.includes('/client')) {
			this.baseUrl += '/client';
		}

		this.setChannelIdFromUrl();
		if (!this.channelId) {
			this.createNewChannel();
		}

		let autoJoin = getQueryVariable('auto-join');
		if (autoJoin) {
			this.user.name = 'auto_join_' + Math.floor(Math.random() * 1000);
			setTimeout(() => {
				this.join();
			}, 500);
		}
		// else if (this.user.session_id) {
		// 	this.join();
		// }
	}

	copyChannelUrl() {
		let el: HTMLInputElement = document.querySelector('#newChannelUrl');
		el.classList.add('highlight');
		navigator.clipboard.writeText(this.newChannelUrl);
		setTimeout(() => {
			el.classList.remove('highlight');
		}, 500);
	}

	get newChannelUrl() {
		return this.baseUrl + '/' + this.channelId;
	}

	cancelNewRoom() {
		this.newChannel = false;
		this.setChannelIdFromUrl();
	}

	async createNewChannel() {
		this.loginError = null;
		this.channelId = null;
		this.newChannel = true;
	}

	joinChannel() {
		if (!this.channelId) {
			this.loginError = 'Enter room link or name';
			return;
		}
		this.join();
	}

	join() {
		state.loadingChannel = true;
		this._join().finally(() => {
			state.loadingChannel = false;
		});
	}

	async _join() {
		if (this.newChannel) {
			this.channelId = await service.getUniqueChannelName();
			if (state.appInfo) {
				state.app_id = state.appInfo.id;
			}
		}
		state.removeQueryFromUrl();
		await state.apiInitPromise;
		if (!state.connected) {
			await api.reconnect();
		}

		let channelId = this.channelId.trim();

		let user = this.user;
		if (!user.name) {
			user.name = 'Guest' + getRandomInt(10, 99);
		}

		let form: IJoinChannel = {
			name: user.name,
			client_id: api.clientId,
			email: null,
			password: null,
			session_id: null,
			channel_id: channelId,
			api_key: '5a05e3ea-59ea-4213-a321-220174478fee',
			audioEnabled: state.audioEnabled
		};

		let r = await service.joinChannel(form);

		if (r.success) {
			localStorage.setItem('user', JSON.stringify(user));
			this.channelId = channelId;

			router.push('/' + channelId);
			//state.showJoinDlg = false;

			if (this.newChannel) {
				state.showShareDlg = true;
			}
		} else if (r.error) {
			this.loginError = r.error;
		}
	}
}

const AppTile = Component((p: { app: IAppInfo }) => {
	return () => (
		<div class={{ appImage: true }} onClick={e => state.featured_apps.forEach(a => (state.appInfo = p.app))}>
			<img src={store_url + p.app.tile_image} />
			<h1>{p.app.name}</h1>
		</div>
	);
});

const Form = Component((p: { l: LState }) => {
	const l = p.l;
	return () => (
		<table>
			<tr>
				<td>
					<label for="name">Your name</label>
				</td>
				<td>
					<input
						id="name"
						ref={focusRef()}
						value={l.user.name}
						onChange={e => (l.user.name = e.target.value)}
						onKeydown={e => e.key === 'Enter' && l.join()}
						spellcheck="false"
					/>
				</td>
			</tr>

			{!l.newChannel && (
				<tr>
					<td>
						<label>Room</label>
					</td>
					<td>
						<input
							class="input-join"
							value={l.channelId}
							placeholder="Enter room link or name"
							onChange={e => (l.channelId = e.target.value)}
						/>
					</td>
				</tr>
			)}
			{l.newChannel && (
				<tr style="display: none">
					<td>
						<label style="flex-shrink: 0">Share Link</label>
					</td>
					<td>
						<div style="display: flex; align-items: center">
							<input class="input-join" readonly id="newChannelUrl" value={l.newChannelUrl} />
							<button class="button share" onClick={e => l.copyChannelUrl()}>
								<Icon name="copy" />
								Copy
							</button>
						</div>
					</td>
				</tr>
			)}
		</table>
	);
});

export const Home = Component(() => {
	const l = Vue.reactive(new LState());
	l.init();

	function noApps() {
		state.appInfo = null;
	}

	return () => (
		<div
			style={{
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background: 'var(--accent-900)'
			}}
		>
			{!state.loadingChannel && (
				<div id="login-dlg" class="client">
					<div class="welcome">
						<Icon name="ga-logo" size="3rem" />
						<div>
							<div class="desc">Free meeting platform with apps!</div>
							{/* {state.firstTimeUser && l.newChannel && (
								<div style="display:flex;flex-direction:column;gap:6px;margin-top: 6px;font-size: 14px;color: #e5b2f5;">
									<div>✶ No Time Limits</div>
									<div>✶ Share audio, video, and screens</div>
									<div>✶ Collaborative apps</div>
								</div>
							)} */}
						</div>
					</div>
					<div style={{ padding: '1em' }}>
						{l.newChannel && (
							<div style="display:flex; gap: 2em;justify-content: center;color: springgreen;margin-bottom: 1em;font-size: 18px;">
								<span>Select an app to use in your room: </span>
							</div>
						)}

						<div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
							{l.newChannel && state.appInfo && (
								<button class="side-app" onClick={noApps}>
									<VTitle text="No App" />
									<Icon name="times-solid" />
								</button>
							)}
							{state.appInfo && <AppTile app={state.appInfo} />}
							{!state.appInfo && l.newChannel && state.featured_apps.map(a => <AppTile app={a} />)}
							{l.newChannel && (
								<button
									class="side-app"
									onClick={e => {
										//state.browseApps();
										router.push('/apps');
									}}
								>
									<VTitle text="More Apps" />
									<Icon name="search" />
								</button>
							)}
						</div>

						<Form l={l} />

						<transition name="fade">
							{l.loginError && (
								<div class="form-row horizontal" id="login-error">
									{l.loginError}
								</div>
							)}
						</transition>
						{l.successMsg && (
							<div class="form-row horizontal" id="success-message">
								{l.successMsg}
							</div>
						)}

						{l.newChannel && (
							<div class="form-buttons">
								<button class="button secondary" onClick={e => l.cancelNewRoom()}>
									Join a Room
								</button>
								<button class="button accent" onClick={e => l.join()}>
									Create Room
								</button>
							</div>
						)}
						{!l.newChannel && (
							<div class="form-buttons">
								<button class="button secondary" onClick={e => l.createNewChannel()}>
									Create New Room
								</button>
								<button class="button accent" onClick={e => l.joinChannel()}>
									Join Room
								</button>
							</div>
						)}
					</div>
				</div>
			)}
			<div></div>
		</div>
	);
});
