import { RouterLink } from 'vue-router';
import { Component } from '../helpers/helper';
import { router } from '../router';
import { base_api, service } from '../service';
import { state, store_url } from '../state';
import { store_api } from '../storeAPI';
import { Icon } from '../components/icon';
import * as path from 'path';

const TAGS = [
	'Math',
	'Reading',
	'Writing',
	'Drawing',
	'Geography',
	'Typing',
	'Spelling',
	'Collaborative',
	'Science',
	'Game'
].sort();

class ModState {
	app: IAppInfo;
	dev: boolean = false;
	lti_mode: boolean = false;

	constructor(app: IAppInfo) {
		this.app = app;

		if (router.currentRoute.value.query.ltik) {
			this.lti_mode = true;
		} else {
			this.lti_mode = false;
		}
	}

	get linkTo() {
		return `app/${this.app.name}/${this.app.id}`;
	}

	openApp(event: Event) {
		if (this.lti_mode) {
			event.stopPropagation();
			store_api.deepLink(this.app);
		} else {
			event.stopPropagation();
			//store_api.launch(this.app.id);
			if (state.hasChannel) {
				api.activateApp(this.app.id, true);
			} else {
				state.appInfo = this.app;
				router.push('/');
			}
		}
	}

	viewApp() {
		if (this.dev) {
			//this.$emit('click', this.app);
		} else {
			//router.push('/app/' + this.app.name + '/' + this.app.id);
		}
	}

	get isCollaborative() {
		return this.app.tags.includes('Collaborative');
	}
}

export const Module = Component((p: { app: IAppInfo }) => {
	const l = Vue.reactive(new ModState(p.app));

	return () => (
		<div class="module">
			<button onClick={e => l.openApp(e)} class="image">
				{l.app.tile_image ? (
					<img src={store_url + l.app.tile_image} />
				) : (
					<div class="placeholder">
						<Icon name="app-cube" size="6rem" style="color: var(--currentColor)" class="cube w-6 h-6" />
					</div>
				)}
			</button>
			<button onClick={e => l.openApp(e)} class="play">
				{!l.lti_mode && <span>Start</span>}
				{l.lti_mode && <span>Add Activity</span>}
				&nbsp;&nbsp;
				<Icon name="play-solid" />
			</button>
			<RouterLink to={l.linkTo} class="name">
				{() => l.app.name}
			</RouterLink>
			<span class="desc">{l.app.description}</span>
			{l.app.tags.length && (
				<div class="tags">
					<Icon name="tag" />
					{l.app.tags.map(tag => (
						<span class="tag" key="tag">
							{tag}&nbsp;
						</span>
					))}
				</div>
			)}
			{/* {l.isCollaborative && (
				<div class="badge" title="multiplayer">
					<Icon name="user-friends-solid" />
				</div>
			)} */}

			<div class="inner">
				<span class="name">{l.app.name}</span>
				<span class="desc">{l.app.description}</span>
				<span style="flex-grow: 1"></span>
				{l.app.tags.length && (
					<div class="tags">
						<Icon name="tag" />
						{l.app.tags.map(tag => (
							<span class="tag" key={tag}>
								{tag}&nbsp;
							</span>
						))}
					</div>
				)}
				{l.app.id && (
					<span class="footer">
						<div class="price">
							<span>Price:</span>
							<span class="amount">Free</span>
						</div>
						<span style="flex-grow: 1"></span>
					</span>
				)}
			</div>
		</div>
	);
});

class BrowseState {
	search: string = null;
	apps: IAppInfo[] = [];
	timer = null;
	searching = false;
	tags: string[] = TAGS;
	activeTags: string[] = [];
	showTagMenu = false;
	labUrl: string = null;

	startSearch(delay?: boolean) {
		this.searching = true;
		if (this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(
			() => {
				this.getApps();
			},
			delay ? 500 : 0
		);
	}

	async getApps() {
		this.apps = await store_api.getApps({ term: this.search, tags: this.activeTags });
		this.searching = false;
	}

	addTag(tag: string) {
		this.showTagMenu = false;
		if (!this.activeTags.includes(tag)) {
			this.activeTags.push(tag);
		}
	}

	removeTag(tag: string) {
		this.activeTags.splice(this.activeTags.indexOf(tag), 1);
	}

	suggest() {
		//SuggestionDlg.open();
	}
}

export const BrowsePage = Component(() => {
	const l = Vue.reactive(new BrowseState());
	const el = Vue.ref(null);

	//l.getApps();
	/*Vue.watchEffect(() => {
		l.searching = true;
		if (l.timer) clearTimeout(l.timer);
		l.getApps();
	});*/

	l.startSearch();
	store_api.getClientUrl().then(r => (l.labUrl = r));

	Vue.onMounted(() => {
		let div: HTMLElement = el.value;
		//let menu = div.querySelector('#tag-menu-container');
		div.onmousedown = e => {
			//@ts-ignore
			//if (menu.contains(e.target)) return;
			l.showTagMenu = false;
		};
	});

	return () => (
		<div id="browse" ref={el} class="store-page">
			<div class="page">
				{/* <div class="discover">
					<span class="inner">
						<Icon name="search" size="1.5em" />
						<input value={l.search} onChange={e => (l.search = e.target.value)} placeholder="Discover Apps" />
						{l.activeTags.map(tag => (
							<div class="activeTag" onClick={e => l.removeTag(tag)}>
								{{ tag }}
							</div>
						))}
						<div id="tag-menu-container" style="position: relative">
							<div>
								<Icon name="tag" size="1.5em" style="color: var(--accent-600); cursor: pointer" />
							</div>
							{l.showTagMenu && (
								<div id="tagMenu">
									{l.tags.map(tag => (
										<div class="tag" onClick={e => l.addTag(tag)}>
											{{ tag }}
										</div>
									))}
								</div>
							)}
						</div>
					</span>
				</div> */}
				{/* <div style="text-align: center; margin: 10px; font-size: 1.2em; text-decoration: underline">
					<a onClick={e => l.suggest()}>Suggest app</a>
				</div> */}
				<div class="modules" style="margin-top: 0">
					{l.apps.map(a => (
						//@ts-ignore
						<Module key={a.id} app={a}></Module>
					))}
				</div>
			</div>
		</div>
	);
});
